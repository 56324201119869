import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { NavLink } from "react-router-dom";

import data from "../shared/data";

/* Navbar component*/
export default class EffectBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /*Toggle collapsible navbar*/
      isOpen: false,
      /*Check if the user has scrolled down or not to change the navbar css class*/
      scroll: false
    };

    this.toggle = this.toggle.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
  }
  toggle() {
    // Toggle modal
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  closeNavbar() {
    /*Close navbar after user has clicked*/
    if (this.state.isOpen === true) {
      this.toggle();
    }
  }

  componentDidMount() {
    var comp = this;
    /**
     * bind to the document scroll detection
     */
    window.onscroll = function (e) {
      var yOffset = 0;
      var currYOffSet = window.pageYOffset;
      if (yOffset < currYOffSet) {
        comp.setState({
          scroll: true
        });
      } else if (currYOffSet === yOffset) {
        comp.setState({
          scroll: false
        });
      }
    };
  }
  render() {
    //NAv links, if it has children create a dropdown using reactstrap components
    const links = data.effectbar.links.map((link, index) => {
      if (link.children) {
        const children = link.children.map((child, ind) => (
          <DropdownItem key={ind}>
            <NavLink
              onClick={this.closeNavbar}
              to={child.link}
              activeClassName="nav-link active"
              className="nav-link inactive"
              exact={true}
              style={{ fontSize: '20px' }}
            >
              {child.name}
            </NavLink>
          </DropdownItem>
        ));
        return (
          <UncontrolledDropdown key={index} nav inNavbar>
            <DropdownToggle nav caret>
              {link.name.toUpperCase()}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink
                  onClick={this.closeNavbar}
                  to={link.link}
                  activeClassName="nav-link active"
                  className="nav-link inactive"
                  style={{ fontSize: '20px' }}
                  exact={true}
                >
                  {link.name}
                </NavLink>
              </DropdownItem>
              {children}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      } else {
        return (
          <NavItem key={index}>
            <NavLink
              onClick={this.closeNavbar}
              to={link.link}
              activeClassName="nav-link active"
              className="nav-link inactive"
              exact={true}
            >
              {link.name.toUpperCase()}
            </NavLink>
          </NavItem>
        );
      }
    });
    return (
      <div>
        {/* Navbar that is either fixed or not depending on whether the user has scrolled or not*/}
        <Navbar
          className={
            this.state.scroll
              ? "navbar fixed-top fixed-theme navbar-expand-lg"
              : "navbar fixed-top navbar-expand-lg"
          }
          expand="md"
        >
          {/* Logo*/}
          <NavbarBrand to="/" className="mr-auto">
            <img
              src={data.effectbar.logo}
              alt=""
              className="d-inline-block align-top"
            />
          </NavbarBrand>
          {/* Collapse toggler*/}
          <NavbarToggler onClick={this.toggle}>
            <i className="toggler fas fa-bars" />
          </NavbarToggler>
          {/* Nav links*/}
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {links}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
