import React, { Component } from "react";
import Banner from "./BannerComponent";
import BreadCrumbs from "./misc/BreadCrumbsComponent";
import Cols from "./misc/ColsComponent";
import data from "../shared/data";
import ReactGA from "react-ga";
//Diagnosis Kits for route /diagnosis/kits-diagnostico/
class DiagnosisKits extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Kits Diagnóstico'
      }
    });
  }

  render() {
    var comp = this;
    
    return (
      <div>
        <Banner
          banner={data.dbskits.banner_img}
          parser={this.props.parser}
          msg={data.dbskits.banner_msg}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          btn_text={data.effectbar.call_to_action.btn_text}
        />
        {/* Breadcrumbs nav for subcomponent*/}
        <BreadCrumbs
          current={data.diagnosis.cols.content[2].title}
          parser={this.props.parser}
          breadcrumbs={data.diagnosis.breadcrumb}
        />

        {/* First section: Importance*/}
        <a className="anchor" id="importance" />

        <div
          style={{
            backgroundColor: data.dbskits.custom_comb1.color
          }}
        >
          <div className="container pb-5">
            <div className="row text-left">
              { /* Kits DBS */ }
              <div className="col-12">
                <h2 className="text-center pb-3">
                  {comp.props.parser.parse(
                    data.dbskits.custom_comb1.title
                  )}
                </h2>
                <p className="text-center">
                  {comp.props.parser.parse(
                    data.dbskits.custom_comb1.text
                  )}
                </p>
                <p className="text-center">
                  <strong>
                    {comp.props.parser.parse(
                     data.dbskits.custom_comb1.sub
                    )}
                  </strong>
                </p>
              </div>
              {/* Contenidos Kit DBS */}
              <div className="col-12 pt-5">
                <h2 className="text-center pb-3">
                  {comp.props.parser.parse(
                    data.dbskits.custom_comb2.content.title
                  )}
                </h2>
                <div className="container pt-3 pb-5">
                  <div className="row">
                    <div className="col-12 col-sm-4 pt-4">
                      <img
                        className="img-fluid"
                        src={data.dbskits.custom_comb2.content.cols1.img}
                        alt=""
                      />
                      <p className="text-center pt-4">
                        {data.dbskits.custom_comb2.content.cols1.text}
                      </p>
                    </div>
                    <div className="col-12 col-sm-4 pt-4">
                      <img
                        className="img-fluid"
                        src={data.dbskits.custom_comb2.content.cols2.img}
                        alt=""
                      />
                      <p className="text-center pt-4">
                        {data.dbskits.custom_comb2.content.cols2.text}
                      </p>
                    </div>
                    <div className="col-12 col-sm-4 pt-4">
                      <img
                        className="img-fluid"
                        src={data.dbskits.custom_comb2.content.cols3.img}
                        alt=""
                      />
                      <p className="text-center pt-4">
                        {data.dbskits.custom_comb2.content.cols3.text}
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>

              {/* La sangre se puede extraer mediante punción venosa o pinchando un dedo */}
              <div className="col-12 pt-5">
                <h2 className="text-center pb-3">
                  {comp.props.parser.parse(
                    data.dbskits.custom_comb2.content2.title
                  )}
                </h2>
                <div className="container pt-3 pb-5">
                  <div className="row">
                    <div className="col-12 col-sm-6 text-center pt-4">
                      <img
                        className="img-fluid"
                        src={data.dbskits.custom_comb2.content2.cols1.img}
                        alt=""
                      />
                      <p className="text-center pt-4">
                        {data.dbskits.custom_comb2.content2.cols1.text}
                      </p>
                    </div>
                    <div className="col-12 col-sm-6 text-center pt-4">
                      <img
                        className="img-fluid"
                        src={data.dbskits.custom_comb2.content2.cols2.img}
                        alt=""
                      />
                      <p className="text-center pt-4">
                        {data.dbskits.custom_comb2.content2.cols2.text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* La sangre se recoge en tarjetas DBS, se procesa y se envía para su análisis */}
              <div className="col-12 pt-5">
                <h2 className="text-center pb-3">
                  {comp.props.parser.parse(
                    data.dbskits.custom_comb2.content3.title
                  )}
                </h2>
                <div className="container pt-3 pb-5">
                  <div className="row">
                    <div className="col-12 col-sm-6 text-center pt-4">
                      <img
                        className="img-fluid"
                        src={data.dbskits.custom_comb2.content3.cols1.img}
                        alt=""
                      />
                      <p className="text-center pt-4">
                        {data.dbskits.custom_comb2.content3.cols1.text}
                      </p>
                    </div>
                    <div className="col-12 col-sm-6 text-center pt-4">
                      <img
                        className="img-fluid"
                        src={data.dbskits.custom_comb2.content3.cols2.img}
                        alt=""
                      />
                      <p className="text-center pt-4">
                        {data.dbskits.custom_comb2.content3.cols2.text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-center pt-4">{data.dbskits.custom_comb2.desc}</p>

            </div>
          </div>
        </div>

          
      </div>
    );
  }
}

export default DiagnosisKits;
