import React, { Component } from 'react';
import data from '../shared/data'
import ReactGA from "react-ga";
import Banner from './BannerComponent.js'

/*Fallback component if user accesses an undefined route*/
class NotFound extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        // ReactGA.pageview('/404');
        window.dataLayer.push({
          event: 'pageview',
          page: {
            url: '/404'
          }
        });
    }
    render() {

        return (
            <div>
                <Banner banner={data.symptoms_neurological.banner_img}
                    msg={data.symptoms_neurological.banner_msg}
                    title={data.effectbar.call_to_action.title}
                    content={data.effectbar.call_to_action.content}
                    parser={this.props.parser}
                    btn_text={data.effectbar.call_to_action.btn_text} />
                {/* Not found*/}
                <div className="container-fluid pt-5">
                    <div className="row mb-auto h-100 justify-content-center align-items-center">
                        <div className="col-12">
                            <h3>WHOOPS!</h3>
                            <p style={{ fontSize: '180px' }}>404</p>
                            <p>Parece que estás perdido, mejor vuelve a la página principal</p>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

export default NotFound;