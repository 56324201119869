import React, { Component } from "react";
import TextBackground from "./misc/TextBackgroundComponent";
import data from "../shared/data";
import ImageComb1 from "./misc/ImageComb1Component";
import ImageComb2 from "./misc/ImageComb2Component";
import Banner from "./BannerComponent";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';
//Home component for route /*/
class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Home'
      }
    });
  }

  render() {
    var comp = this;
    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Enfermedad de Gaucher para Profesionales de la Salud</title>
          <meta name="description" content="¿Muestra su paciente signos de enfermedad de Gaucher? Descubra si los síntomas apuntan a un diagnóstico de enfermedad de Gaucher" />
          <meta name="keywords" content="Enfermedad de Gaucher, Enfermedad huérfana, Enfermedad Genética, Enfermedad de depósito lisosomal" />
        </Helmet>
        {/* Banner component*/}
        <Banner
          banner={data.home.banner_img}
          msg={data.home.banner_msg}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          parser={this.props.parser}
          btn_text={data.effectbar.call_to_action.btn_text}
        />
        {/* Site purpose*/}
        <a className="anchor" id="purpose" />

        <TextBackground
          title={data.home.banner1.title}
          parser={this.props.parser}
          content={data.home.banner1.content}
          color={data.home.banner1.color}
        />

        {/* General description*/}
        <a className="anchor" id="general-description" />

        <ImageComb1
          title={data.home.comb1_1.title}
          parser={this.props.parser}
          sub1={data.home.comb1_1.sub1}
          txt1={data.home.comb1_1.txt1}
          img={data.home.comb1_1.img}
          references={data.home.comb1_1.refs}
          color={data.home.comb1_1.color}
        />

        {/* Diagnosis confirmation*/}
        <a className="anchor" id="confirming-diagnosis" />
        <ImageComb1
          title={data.home.comb1_2.title}
          parser={this.props.parser}
          sub1={data.home.comb1_2.sub1}
          txt1={data.home.comb1_2.txt1}
          img={data.home.comb1_2.img}
          references={data.home.comb1_2.refs}
          color={data.home.comb1_2.color}
        />
        {/* Types of Gaucher disease*/}

        <a className="anchor" id="types-parallax" />

        <div
          className="container-fluid bgimage parallax"
          style={{ backgroundImage: "url(/img/black.jpg)" }}
        >
          <div className="row h-100 justify-content-center align-items-center text-center">
            <div className="col-12 align-self-center text-light">
              <h1>{comp.props.parser.parse(data.home.parallax.title)}</h1>
              <p>{comp.props.parser.parse(data.home.parallax.content)}</p>
            </div>
          </div>
        </div>

        <a className="anchor" id="types" />
        <ImageComb2
          content={data.home.comb2_1.content}
          parser={this.props.parser}
          img={data.home.comb2_1.img}
          references={data.home.comb2_1.refs}
        />
      </div>
    );
  }
}

export default Home;
