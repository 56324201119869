import React, { Component } from "react";
import Banner from "./BannerComponent";
import BreadCrumbs from "./misc/BreadCrumbsComponent";
import data from "../shared/data";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';
//Importance of Early Diagnosis Component for route /diagnosis/importance-of-early-diagnosis*/
class ImportanceOfEarlyDiag extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Importancia del diagnóstico precoz'
      }
    });
  }

  render() {
    var comp = this;
    const refs1 = data.importance_early_diag.custom_comb1.refs.map(
      (ref, index) => <li className="references" key={index}>{ref}</li>
    );
    const refs2 = data.importance_early_diag.custom_comb2.refs.map(
      (ref, index) => <li className="references" key={index}>{ref}</li>
    );
    const imgs = data.importance_early_diag.custom_comb2.imgs.map(
      (img, index) => (
        <div className="col-12 pt-3 pb-3 text-center" key={index}>
          <h4 className="title pb-3">{comp.props.parser.parse(img.title)}</h4>
          <img src={img.src} className="img-fluid" alt="" />
        </div>
      )
    );
    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Importancia del diagnóstico precoz - Enfermedad de Gaucher | Enfermedades Genéticas</title>
          <meta name="description" content="El retraso en el diagnóstico es común en la enfermedad de Gaucher. El tiempo promedio desde la primera aparición de los síntomas hasta el diagnóstico final es de 48 meses …" />
          <meta name="keywords" content="Diagnóstico precoz en Enfermedad de Gaucher, Aparición temprana de la enfermedad de Gaucher, Diagnóstico temprano enfermedad de Gaucher" />
        </Helmet>
        <Banner
          banner={data.symptoms_hematological.banner_img}
          parser={this.props.parser}
          msg={data.symptoms_hematological.banner_msg}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          btn_text={data.effectbar.call_to_action.btn_text}
        />
        {/* Breadcrumbs nav for subcomponent*/}
        <BreadCrumbs
          current={data.diagnosis.cols.content[1].title}
          parser={this.props.parser}
          breadcrumbs={data.diagnosis.breadcrumb}
        />

        {/* First section: Importance*/}
        <a className="anchor" id="importance" />

        <div
          style={{
            backgroundColor: data.importance_early_diag.custom_comb1.color
          }}
        >
          <div className="container pb-5">
            <div className="row text-left">
              <div className="col-12">
                <h2>
                  {comp.props.parser.parse(
                    data.importance_early_diag.custom_comb1.title
                  )}
                </h2>
              </div>
              <div className="col-12">
                <h4>
                  {comp.props.parser.parse(
                    data.importance_early_diag.custom_comb1.sub1
                  )}
                </h4>
                <p>
                  {comp.props.parser.parse(
                    data.importance_early_diag.custom_comb1.txt1
                  )}
                </p>
              </div>
              <div className="col-12">
                <ol>{refs1}</ol>
              </div>
            </div>
          </div>
        </div>

        <a className="anchor" id="consequences" />
        {/* disease consequences*/}
        <div
          style={{
            backgroundColor: data.importance_early_diag.custom_comb2.color
          }}
        >
          <div
            className="container pt-5"
            style={{
              border: "1px solid transparent",
              borderTopColor: "grey"
            }}
          >
            <div className="row text-left">
              <div className="col-12">
                <h2>
                  {comp.props.parser.parse(
                    data.importance_early_diag.custom_comb2.title
                  )}
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <h4>
                  {comp.props.parser.parse(
                    data.importance_early_diag.custom_comb2.sub
                  )}
                </h4>
              </div>
              <div className="col-12 col-md-6">
                <p>
                  {comp.props.parser.parse(
                    data.importance_early_diag.custom_comb2.txt
                  )}
                </p>
              </div>

              {imgs}

              <div className="pt-5 pb-5 col-12 text-left">
                <ol>{refs2}</ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImportanceOfEarlyDiag;
