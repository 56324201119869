import React, { Component } from "react";
/*CSS*/
import "./App.css";
import "./css/styles.css";
import "./css/google-fonts.css";
/*Routing*/
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import Home from "./components/HomeComponent";
//Symptoms
import Symptoms from "./components/SymptomsComponent";
import SymptomsHematological from "./components/SymptomsHematologicalComponent";
import SymptomsSkeletal from "./components/SymptomsSkeletalComponent";
import SymptomsNeurological from "./components/SymptomsNeurologicalComponent";
//Diagnosis
import Diagnosis from "./components/DiagnosisComponent";
import DiagnosticAlg from "./components/DiagnosticAlgComponent";
import ImportanceOfEarlyDiag from "./components/ImportanceOfEarlyDiagnosisComponent";
import DiagnosisKits from "./components/DiagnosisKitsComponent";
//Others
import Management from "./components/ManagementComponent";
import ReferralInfo from "./components/ReferralInfoComponent";
import Epidemiology from "./components/EpidemiologyComponent";
import Terms from "./components/TermsComponent";
import PrivacyPolicy from "./components/PrivacyPolicyComponent";
import Footer from "./components/FooterComponent";
import HCPVerification from "./components/HCPVerificationComponent";
import DiagnosisTest from "./components/DiagnosisTestComponent";
import NotFound from "./components/NotFoundComponent";
//Member
import Member from "./components/MemberComponent";
/*Floating component*/
import FloatingBars from "./components/FloatingBarsComponent";
/*Navbar*/
import EffectBar from "./components/EffectBarComponent";
/*GA*/
import ReactGA from "react-ga";
// ReactGA.initialize("UA-117777395-16");
/*GTM*/
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-T34TPFB'
}
TagManager.initialize(tagManagerArgs);
/*Parse HTML content por superscripts*/
var HtmlToReactParser = require("html-to-react").Parser;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hcpContinue: false,
      showComponents: false,
      parser: new HtmlToReactParser()
    };
    //Should redirect method
    this.redirect = this.redirect.bind(this);
    //Check ifperson is HCP or not
    this.isHCP = this.isHCP.bind(this);
    // Handle the view of components in HCP verification view
    this.handleComponents = this.handleComponents.bind(this);
  }

  isHCP() {
    this.setState({
      hcpContinue: true
    });
  }

  redirect() {
    this.setState({
      hcpContinue: true
    });
    return <Redirect to="/verificacionHcp" />;
  }

  handleComponents() {
    console.log("SHOW");
    this.setState({
      showComponents: true
    });
  }

  componentWillMount() {
    /*Check if person is HCP or not*/
    var storage = localStorage.getItem("isHCP");
    this.setState({
      hcpContinue: storage
    });
  }

  componentDidMount() {
    this.setState({
      showComponents: !window.location.href.includes("verificacionHcp")
    });
  }

  render() {
    return (
      <div className="App">
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          {this.state.showComponents ? <EffectBar /> : null}
          {!this.state.hcpContinue ? this.redirect() : null}
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Home parser={this.state.parser} />}
            />
            <Route
              exact
              path="/sintomas/"
              component={() => <Symptoms parser={this.state.parser} />}
            />
            <Route
              path="/sintomas/hematologicos/"
              component={() => (
                <SymptomsHematological parser={this.state.parser} />
              )}
            />
            <Route
              path="/sintomas/esqueleticos/"
              component={() => <SymptomsSkeletal parser={this.state.parser} />}
            />
            <Route
              path="/sintomas/neurologicos/"
              component={() => (
                <SymptomsNeurological parser={this.state.parser} />
              )}
            />
            <Route
              exact
              path="/diagnostico/"
              component={() => <Diagnosis parser={this.state.parser} />}
            />
            <Route
              exact
              path="/diagnostico/diagnostico-algoritmos"
              component={() => <DiagnosticAlg parser={this.state.parser} />}
            />
            <Route
              exact
              path="/diagnostico/importancia-diagnostico-temprano"
              component={() => (
                <ImportanceOfEarlyDiag parser={this.state.parser} />
              )}
            />
            <Route
              exact
              path="/manejo/"
              component={() => <Management parser={this.state.parser} />}
            />
            <Route
              exact
              path="/asesoria-genetica/"
              component={() => <ReferralInfo parser={this.state.parser} />}
            />
            <Route
              exact
              path="/epidemiologia/"
              component={() => <Epidemiology parser={this.state.parser} />}
            />
            <Route
              exact
              path="/diagnostico/prueba-diagnostico/"
              component={() => <DiagnosisTest parser={this.state.parser} />}
            />
            <Route
              exact
              path="/terminos/"
              component={() => <Terms parser={this.state.parser} />}
            />
            <Route
              exact
              path="/aviso-de-privacidad/"
              component={() => <PrivacyPolicy parser={this.state.parser} />}
            />
            <Route
              exact
              path="/verificacionHcp/"
              component={() => (
                <HCPVerification
                  isHCP={this.isHCP}
                  parser={this.state.parser}
                  handleComponents={this.handleComponents}
                />
              )}
            />
            <Route
              exact
              path="/comuniquese-con-miembro-del-equipo-medico/"
              component={() => <Member parser={this.state.parser} />}
            />
            <Route component={() => <NotFound />} />
          </Switch>
          {!window.location.href.includes("verificacionHcp") ? <FloatingBars /> : null}
          {!window.location.href.includes("verificacionHcp") ? <Footer /> : null}
        </Router>
      </div>
    );
  }
}

export default App;
