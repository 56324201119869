import React, { Component } from "react";

//Image combination component type 1
class ImageComb1 extends Component {
  render() {
    var comp = this;
    //Variables declaration
    var title,
      sub1,
      txt1,
      img,
      imgTitle,
      imgdesc,
      sub2,
      txt2,
      refs,
      refs2,
      links1,
      links2;

    if (this.props.title)
      title = (
        <h2 className={comp.props.centerTitle ? "text-center pb-3" : ""}>
          {comp.props.parser.parse(this.props.title)}
        </h2>
      );
    sub1 = <h5>{comp.props.parser.parse(this.props.sub1)}</h5>;
    if (this.props.txt1)
      txt1 = this.props.txt1.map((text, index) => (
        <p key={index}>{comp.props.parser.parse(text)}</p>
      ));
    //Split links by sep ;;;
    if (this.props.links1)
      links1 = this.props.links1.map((text, index) => (
        <li key={index}>
          <a href={text.split(";;;")[1]}>
            {comp.props.parser.parse(text.split(";;;")[0])}
          </a>
        </li>
      ));
    if (this.props.references) {
      refs = this.props.references.map((ref, index) => {
        return <li key={index}>{ref}</li>;
      });
    }
    //Component rendering if there is an image
    if (this.props.img) {
      img = (
        <img
          src={this.props.img}
          className="img-fluid"
          alt=""
          style={{ width: "100%" }}
        />
      );
      if (this.props.imgdesc) {
        imgTitle = comp.props.parser.parse(this.props.imgtitle);
        imgdesc = this.props.imgdesc.map((desc, index) => {
          return <p key={index}>{comp.props.parser.parse(desc)}</p>;
        });
      }
      return (
        <section style={{ backgroundColor: this.props.color }}>
          <div className="container text-left text-break p-5">
            <div className="row">
              <div className="col-12">{title}</div>
              <div className="col-12 col-lg-5">
                {sub1}
                {txt1}
              </div>
              <div className="col-12 col-lg-7">
                {img}
                <div className="img-desc">
                  <h6>{imgTitle}</h6>
                  {imgdesc}
                </div>
              </div>
              <div className="col-12">
                <ul className="list-unstyled">{links1}</ul>
                <div className="refs">
                  <ol>{refs}</ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      //Component rendering if there are no images
      sub2 = <h5>{comp.props.parser.parse(this.props.sub2)}</h5>;
      if (this.props.txt2)
        txt2 = this.props.txt2.map((text, index) => (
          <p key={index}>{comp.props.parser.parse(text)}</p>
        ));
      if (this.props.links2)
        links2 = this.props.links2.map((text, index) => (
          <li key={index}>
            <a href={text.split(";;;")[1]}>
              {comp.props.parser.parse(text.split(";;;")[0])}
            </a>
          </li>
        ));
      if (this.props.refs2)
        refs2 = this.props.refs2.map((text, index) => (
          <p className="references" key={index}>{comp.props.parser.parse(text)}</p>
        ));

      return (
        <section style={{ backgroundColor: this.props.color }}>
          <div className="container text-left text-break p-5">
            <div className="row">
              <div className="col-12">{title}</div>
              <div className="col-12 col-md-6">
                {sub1}
                {txt1}
                <ul className="list-unstyled">{links1}</ul>
              </div>
              <div className="col-12 col-md-6">
                {sub2}
                {txt2}
                {refs2}
                <ul className="hover-red">{links2}</ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md refs">
                <ol>{refs}</ol>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

export default ImageComb1;
