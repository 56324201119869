import React, { Component } from "react";
import ImageComb1 from "./misc/ImageComb1Component";
import Banner from "./BannerComponent";
import data from "../shared/data";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';
/*ReferralInfo component for route /referral-info/*/
class ReferralInfo extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Asesoría Genética'
      }
    });
  }

  render() {
    var comp = this;
    const customReferences = data.referral_info.custom_comb2.refs.map(
      (ref, index) => <li className="references white" key={index}>{ref}</li>
    );
    var comp = this;
    const customReferences1 = data.referral_info.custom_comb.refs1.map(
      (ref, index) => <li className="references" key={index}>{ref}</li>
    );
    const customReferences2 = data.referral_info.custom_comb.refs2.map(
      (ref, index) => <li className="references " key={index}>{ref}</li>
    );

    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Asesoría Genética - Enfermedad de Gaucher | Enfermedades Genéticas</title>
          <meta name="description" content="Cuando existe la sospecha de que un paciente puede verse afectado por la enfermedad de Gaucher, se debe realizar un Análisis genealógico …" />
          <meta name="keywords" content="Asesoría Genética enfermedad de Gaucher, Análisis de pedigree enfermedad de Gaucher, Análisis familiar enfermedad de Gaucher" />
        </Helmet>
        <Banner
          banner={data.referral_info.banner_img}
          msg={data.referral_info.banner_msg}
          desc={data.referral_info.banner_desc}
          parser={this.props.parser}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          btn_text={data.effectbar.call_to_action.btn_text}
        />
        {/*<a className="anchor" id="referral-info" />*/}
        {/*<ImageComb1
          title={data.referral_info.comb1_1.title}
          txt1={data.referral_info.comb1_1.txt1}
          txt2={data.referral_info.comb1_1.txt2}
          sub2={data.referral_info.comb1_1.sub2}
          parser={this.props.parser}
          references={data.referral_info.comb1_1.refs}
          color={data.referral_info.comb1_1.color}
        />*/}

        {/* Pedigree analysis component with centered image*/}
        <a className="anchor" id="pedigree-analysis" />
        <div
          className="text-justify"
          style={{ backgroundColor: data.referral_info.custom_comb.color }}
        >
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-12">
                <h2>
                  {comp.props.parser.parse(
                    data.referral_info.custom_comb.title
                  )}
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <h5>
                  {comp.props.parser.parse(data.referral_info.custom_comb.sub1)}
                </h5>
              </div>
              <div className="col-12 col-md-6">
                <p>
                  {comp.props.parser.parse(data.referral_info.custom_comb.txt1)}
                </p>
                <ol>{customReferences1}</ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 offset-md-1 col-md-10">
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src={data.referral_info.custom_comb.img}
                  alt=""
                />
                <p className="text-center">
                  {comp.props.parser.parse(
                    data.referral_info.custom_comb.imgdesc
                  )}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <p>
                  {comp.props.parser.parse(data.referral_info.custom_comb.txt2)}
                </p>
              </div>
              <div className="col-12 col-md-6">
                <p>
                  {comp.props.parser.parse(data.referral_info.custom_comb.txt3)}
                </p>
                <ol>{customReferences2}</ol>
              </div>
            </div>
          </div>
        </div>

        {/* black heritage component*/}
        <a className="anchor" id="heritage"/>

        <div className="text-left custom-banner">
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-12">
                <h3>
                  {comp.props.parser.parse(data.referral_info.custom_comb2.title)}
                </h3>
              </div>
              <div className="col-12 p-3" />
                <div className="col-12 col-md-6">
                  <h6>
                    {comp.props.parser.parse(data.referral_info.custom_comb2.txt1)}
                  </h6>
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    {comp.props.parser.parse(data.referral_info.custom_comb2.txt2)}
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <img
                    className="img-fluid"
                    src={data.referral_info.custom_comb2.img}
                    alt=""
                  />
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    {comp.props.parser.parse(data.referral_info.custom_comb2.txt3)}
                  </p>
                  <ol>{customReferences}</ol>
                </div>
              </div>
            </div>
          </div>
   

        <a className="anchor" id="genetic_counseling" />
        <ImageComb1
          title={data.referral_info.comb1_2.title}
          parser={this.props.parser}
          txt1={data.referral_info.comb1_2.txt1}
          txt2={data.referral_info.comb1_2.txt2}
          references={data.referral_info.comb1_2.refs}
          color={data.referral_info.comb1_2.color}
        />
      </div>
    );
  }
}

export default ReferralInfo;
