import React, { Component } from "react";
import data from "../shared/data";

import { NavLink } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Banner from "./BannerComponent";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';
// DiagnosisTest component for route /diagnosis-test/*
class DiagnosisTest extends Component {
  constructor(props) {
    super(props);
    // Current state
    this.state = {
      currentTest: null,
      showTestModal: false
    };
    //MEthods
    this.showTest = this.showTest.bind(this);
    this.testToggle = this.testToggle.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Prueba de diagnóstico'
      }
    });
  }

  showTest(test) {
    //Show modal
    this.setState({
      showTestModal: true,
      currentTest: test
    });
  }

  testToggle() {
    //Action after closing modal
    this.setState({
      showTestModal: false
    });
  }
  render() {
    // contact information for page
    const contacts = data.diagnosis_test.step3.contacts.map(
      (contact, index) => {
        return (
          <div className="row align-items-center pb-2" key={index}>
            <div className="col-4 col-lg-2">
              <img className="img-fluid" src={contact.icon} alt="Contact" />
            </div>
            <div className="col-8 col-lg-10">
              {contact.cta.map((cta, ind) => {
                return (
                  <span key={ind} className="text-break">
                    <a href={cta.link}>{cta.text}</a>
                    <br />
                  </span>
                );
              })}
            </div>
          </div>
        );
      }
    );
    // Test information for page
    // Test information for page
    const tests = data.diagnosis_test.step2.tests.map((test, index) => {
      return (
        <div className="row align-items-center" key={index}>
          <div className="col-9 col-sm-10 col-lg-9">
            <p>- {test.title}</p>
          </div>
          <div className="col-3 col-sm-2 col-lg-2 p-lg-2">
            <img
              className="img-fluid diagnosis-information"
              onClick={() => this.showTest(test)}
              alt="info-icon"
              src="/svg/infoGaucher.svg"
            />
          </div>
        </div>
      );
    });

    // Test modal
    var testModal = (
      <Modal
        isOpen={this.state.showTestModal}
        toggle={this.testToggle}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={this.testToggle}>
          {this.state.currentTest ? this.state.currentTest.title : null}
        </ModalHeader>
        <ModalBody>
          {this.state.currentTest
            ? this.state.currentTest.text.map((txt, index) => {
              return <p key={index}>{txt}</p>;
            })
            : null}
        </ModalBody>
      </Modal>
    );

    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Prueba de diagnóstico - Enfermedad de Gaucher | Enfermedades Genéticas</title>
        </Helmet>
        <Banner
          banner={data.diagnosis_test.banner_img}
          msg={data.diagnosis_test.banner_msg}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          parser={this.props.parser}
          btn_text={data.effectbar.call_to_action.btn_text}
        />
        <div id="recognizing-gaucher">
          <div className="container text-left">
            {/* General description*/}
            <div className="row align-items-center">
              <div className="col-12">
                <br />
                <p>
                  Si su paciente presenta hallazgos clínicos compatibles con la
                  Enfermedad de Gaucher, tenga en cuenta que puede solicitar
                  una prueba a través del Programa de Diagnóstico.
                </p>
              </div>
            </div>
            {/* Steps */}
            <div className="row align-items-center">
              {/* First col */}
              <div className="col-12 col-lg-6 pb-5">
                {/* Step 1*/}
                <div className="row mb-5 no-gutters align-items-center icon-div">
                  <div className="col-4 text-right">
                    <img
                      className="img-fluid"
                      alt="Icon of First Step"
                      src={data.diagnosis_test.step1.img}
                    />
                  </div>
                  <div className="col-8 icon-description pl-4 pr-2">
                    <h4>{data.diagnosis_test.step1.header}</h4>
                    <p>{data.diagnosis_test.step1.text}</p>
                    <div className="row">
                      <div className="col-12 col-md-8 offset-col-md-2">
                        <div className="cta text-center text-break">
                          <Button
                            color="danger"
                            className="diagnosis-information"
                          >
                            <NavLink
                              to={data.diagnosis_test.step1.ctaLink}
                              exact={true}
                            >
                              {data.diagnosis_test.step1.ctaText}
                            </NavLink>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Step 2*/}
                <div className="row no-gutters align-items-center icon-div">
                  <div className="col-4 text-right">
                    <img
                      className="img-fluid"
                      src={data.diagnosis_test.step2.img}
                      alt="Icon of Second Step"
                    />
                  </div>
                  <div className="col-8 icon-description pl-4 pr-2">
                    <h4>{data.diagnosis_test.step2.header}</h4>
                    {tests}
                  </div>
                </div>
              </div>
              {/* Second col*/}
              <div className="col-12 col-lg-6 pb-5">
                {/* Step 3*/}
                <div className="row mb-5 no-gutters align-items-center icon-div">
                  <div className="col-4 text-right">
                    <img
                      className="img-fluid"
                      alt="Icon of Third Step"
                      src={data.diagnosis_test.step3.img}
                    />
                  </div>
                  <div className="col-8 icon-description pl-4 pr-2">
                    <h4>{data.diagnosis_test.step3.header}</h4>
                    <p>{data.diagnosis_test.step3.text}</p>
                    <div className="contacts-container p-3">{contacts}</div>
                  </div>
                </div>
                {/* Step 4*/}
                <div className="row no-gutters align-items-center icon-div">
                  <div className="col-4 text-right">
                    <img
                      className="img-fluid"
                      src={data.diagnosis_test.step4.img}
                      alt="Icon of Fourth Step"
                    />
                  </div>
                  <div className="col-8 icon-description pl-4 pr-2">
                    <h4>{data.diagnosis_test.step4.header}</h4>
                    <p>{data.diagnosis_test.step4.text}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <p>{data.diagnosis_test.disclaimer_text}</p>
          </div>
          <div className="container-fluid pt-5 pb-1">
            <div
              className="row pt-5 pb-5"
              style={{ backgroundColor: "#ad1923" }}
            >
              <div className="col-" />
            </div>
          </div>
        </div>

        {testModal}
      </div>
    );
  }
}

export default DiagnosisTest;
