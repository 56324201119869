import React, { Component } from "react";
//Banner component
class Banner extends Component {
  render() {
    var comp = this;

    //Comment to add the newsletter
    var gaucherNewsLetter = <div />;

    //Display image if available
    if (this.props.banner) {
      return (
        <div>
          <div
            className="container-fluid bgimage"
            style={{ backgroundImage: "url(" + this.props.banner + ")" }}
          >
            <div className="row h-100 justify-content-center text-left align-items-center text-center">
              <div className="col-xs-12 align-self-center">
                <h3>{comp.props.parser.parse(this.props.msg)}</h3>
                <p>{comp.props.parser.parse(this.props.desc)}</p>
              </div>
            </div>
          </div>
          {gaucherNewsLetter}
        </div>
      );
    } else {
      //If there is no image return a separator
      return <div className="p-5" />;
    }
  }
}

export default Banner;
