import React, { Component } from "react";
import data from "../shared/data";
import ImageComb1 from "./misc/ImageComb1Component";
import BreadCrumbs from "./misc/BreadCrumbsComponent";
import Banner from "./BannerComponent";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';

/*Hemtalogical symptoms component for route /symptoms/hematological/ */
class SymptomsHematological extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Síntomas Hematológicos'
      }
    });
  }

  render() {
    var comp = this;
    //References
    const customReferences1 = data.symptoms_hematological.custom_comb.refs1.map(
      (ref, index) => <li className="references" key={index}>{ref}</li>
    );
    const customReferences2 = data.symptoms_hematological.custom_comb.refs2.map(
      (ref, index) => <li className="references" key={index}>{ref}</li>
    );
    // Refs banner
    const referencesBanner = data.symptoms_hematological.banner.refs.map(
      (ref, index) => <li className="references white" key={index}>{ref}</li>
    );

    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Sintomas Hematológicos - Enfermedad de Gaucher| Enfermedades Genéticas</title>
          <meta name="description" content="Las características hematológicas de la enfermedad de Gaucher incluyen leucopenia y trastornos de la coagulación, pero las más comunes son la anemia y la trombocitopenia .." />
          <meta name="keywords" content="Compromiso hematológico, Trombocitopenia, Anemia, Hiperferritinemia, Hiperesplenismo, Deterioro hematopoyético, Deficiencia de la glucocerebrosidasa" />
        </Helmet>
        <Banner
          banner={data.symptoms_hematological.banner_img}
          msg={data.symptoms_hematological.banner_msg}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          parser={this.props.parser}
          btn_text={data.effectbar.call_to_action.btn_text}
        />
        {/* Breadcrumbs for subcomponent navigation*/}
        <BreadCrumbs
          current={data.symptoms.cols.content[1].title}
          parser={this.props.parser}
          breadcrumbs={data.symptoms.breadcrumb}
        />

        {/* signs and symptoms*/}
        <a className="anchor" id="signs-and-symptoms" />

        <div
          className="text-justify"
          style={{
            backgroundColor: data.symptoms_hematological.custom_comb.color
          }}
        >
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-12 pb-3">
                <h2 className="text-center">
                  {comp.props.parser.parse(
                    data.symptoms_hematological.custom_comb.title
                  )}
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <h5>
                  {comp.props.parser.parse(
                    data.symptoms_hematological.custom_comb.sub1
                  )}
                </h5>
              </div>
              <div className="col-12 col-md-6">
                <p>
                  {comp.props.parser.parse(
                    data.symptoms_hematological.custom_comb.txt1
                  )}
                </p>
              </div>
              <div className="col-12">
                <img
                  className="img-fluid"
                  src={data.symptoms_hematological.custom_comb.img}
                  alt=""
                />
                <p>
                  {comp.props.parser.parse(
                    data.symptoms_hematological.custom_comb.imgdesc
                  )}
                </p>
              </div>
              <div className="col-12 col-md-6">
                <ol>{customReferences1}</ol>
              </div>
              <div className="col-12 col-md-6">
                <ol>{customReferences2}</ol>
              </div>
            </div>
          </div>
        </div>
        {/* Symptoms*/}
        <a className="anchor" id="symptom_1" />
        <ImageComb1
          title={data.symptoms_hematological.comb1_1.title}
          txt1={data.symptoms_hematological.comb1_1.txt1}
          refs2={data.symptoms_hematological.comb1_1.refs2}
          parser={this.props.parser}
          color={data.symptoms_hematological.comb1_1.color}
        />

        <a className="anchor" id="symptom_2" />
        <ImageComb1
          title={data.symptoms_hematological.comb1_2.title}
          txt1={data.symptoms_hematological.comb1_2.txt1}
          refs2={data.symptoms_hematological.comb1_2.refs2}
          parser={this.props.parser}
          color={data.symptoms_hematological.comb1_2.color}
        />

        <a className="anchor" id="symptom_3" />
        <ImageComb1
          title={data.symptoms_hematological.comb1_3.title}
          txt1={data.symptoms_hematological.comb1_3.txt1}
          refs2={data.symptoms_hematological.comb1_3.refs2}
          parser={this.props.parser}
          color={data.symptoms_hematological.comb1_3.color}
        />
        {/* Other common anomalies*/}
        <a className="anchor" id="other-common-anomalies" />

        <div className="custom-banner pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8">
                <h4>
                  {comp.props.parser.parse(
                    data.symptoms_hematological.banner.text
                  )}
                </h4>
              </div>
              <div className="col-12 col-md-4">
                <ol>{referencesBanner}</ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SymptomsHematological;
