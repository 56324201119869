import React, { Component } from "react";
import Cols from "./misc/ColsComponent";
import data from "../shared/data";
import ImageComb1 from "./misc/ImageComb1Component";
import Banner from "./BannerComponent";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';

/*Symptoms component for route /symptoms/*/
class Symptoms extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Síntomas'
      }
    });
  }

  render() {
    return (
      <div>
      {/* META TAGS */}
      <Helmet>
        <title>Sintomas - Enfermedad de Gaucher | Enfermedades Genéticas</title>
        <meta name="description" content="Síntomas en la enfermedad de Gaucher, la acumulación de células de Gaucher puede causar agrandamiento del bazo y el hígado y, posteriormente, interferir con su función …" />
        <meta name="keywords" content="Síntomas en la enfermedad de Gaucher" />
      </Helmet>
        <Banner
          banner={data.symptoms.banner_img}
          msg={data.symptoms.banner_msg}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          parser={this.props.parser}
          btn_text={data.effectbar.call_to_action.btn_text}
        />

        {/* Sections*/}
        <a className="anchor" id="symptom_types" />
        <Cols
          color={data.symptoms.cols.color}
          parser={this.props.parser}
          content={data.symptoms.cols.content}
        />

        <a className="anchor" id="symptoms_overview" />
        <ImageComb1
          title={data.symptoms.comb1_1.title}
          sub1={data.symptoms.comb1_1.sub1}
          txt1={data.symptoms.comb1_1.txt1}
          parser={this.props.parser}
          img={data.symptoms.comb1_1.img}
          imgtitle={data.symptoms.comb1_1.imgtitle}
          imgdesc={data.symptoms.comb1_1.imgdesc}
          references={data.symptoms.comb1_1.refs}
          color={data.symptoms.comb1_1.color}
        />

        <a className="anchor" id="visceral" />
        <ImageComb1
          title={data.symptoms.comb1_2.title}
          txt1={data.symptoms.comb1_2.txt1}
          img={data.symptoms.comb1_2.img}
          imgdesc={data.symptoms.comb1_2.imgdesc}
          parser={this.props.parser}
          references={data.symptoms.comb1_2.refs}
          color={data.symptoms.comb1_2.color}
        />
      </div>
    );
  }
}

export default Symptoms;
