import React, {Component} from 'react';
import ImageComb1 from './misc/ImageComb1Component';
import ImageComb2 from './misc/ImageComb2Component';
import Banner from './BannerComponent';
import data from '../shared/data';
import BreadCrumbs from './misc/BreadCrumbsComponent';
import ReactGA from 'react-ga';
import { Helmet} from 'react-helmet';
/*Component for diagnostic algorithm on route /diagnosis/diagnostic-algorithms*/
class DiagnosticAlg extends Component
{
    componentDidMount()
    {
        window.scrollTo(0,0);
        // ReactGA.pageview(window.location.pathname);
        window.dataLayer.push({
          event: 'pageview',
          page: {
            url: window.location.pathname,
            title: 'Algoritmos de diagnóstico'
          }
        });
    }
    
    render()
    {   
        return(
            <div>
                {/* META TAGS */}
                <Helmet>
                  <title>Algoritmos de diagnóstico - Enfermedad de Gaucher | Enfermedades Genéticas</title>
                  <meta name="description" content="Hay tres algoritmos de detección que se aplican a poblaciones diferentes y específicas. Se pueden usar para ayudar a los profesionales de la salud a diagnosticar …" />
                  <meta name="keywords" content="Algoritmos de diagnóstico enfermedad de Gaucher" />
                </Helmet>
                <Banner banner={data.symptoms_hematological.banner_img}
                    parser={this.props.parser}
                    msg={data.symptoms_hematological.banner_msg}
                    title={data.effectbar.call_to_action.title}
                    content={data.effectbar.call_to_action.content}
                    btn_text={data.effectbar.call_to_action.btn_text}/>

                {/* Breadcrumbs nav for subcomponent*/}
                <BreadCrumbs current={data.diagnosis.cols.content[0].title}
                    parser={this.props.parser}
                    breadcrumbs={data.diagnosis.breadcrumb}/>


                <a className="anchor" id="diagnostic_algorithms"/>
                    <ImageComb1 title={data.diagnostic_alg.comb1_1.title}
                        sub1={data.diagnostic_alg.comb1_1.sub1}
                        txt2={data.diagnostic_alg.comb1_1.txt2}
                        links2={data.diagnostic_alg.comb1_1.links2}
                        references={data.diagnostic_alg.comb1_1.refs}
                        parser={this.props.parser}
                        color={data.diagnostic_alg.comb1_1.color}/>
                {/* Algorithms description*/}
                <a className="anchor" id="ashkenazi"/>
                    <ImageComb2 title={data.diagnostic_alg.comb2_1.title}
                        parser={this.props.parser}
                        content={data.diagnostic_alg.comb2_1.content}
                        img={data.diagnostic_alg.comb2_1.img}
                        references={data.diagnostic_alg.comb2_1.refs}
                        color={data.diagnostic_alg.comb2_1.color}/>
                
                <a className="anchor" id="no-ashkenazi"/>
                    <ImageComb2 title={data.diagnostic_alg.comb2_2.title}
                        parser={this.props.parser}
                        content={data.diagnostic_alg.comb2_2.content}
                        img={data.diagnostic_alg.comb2_2.img}
                        references={data.diagnostic_alg.comb2_2.refs}
                        color={data.diagnostic_alg.comb2_2.color}/>
                <a className="anchor" id="pediatrico"/>
                    <ImageComb2 title={data.diagnostic_alg.comb2_3.title}
                        parser={this.props.parser}
                        content={data.diagnostic_alg.comb2_3.content}
                        img={data.diagnostic_alg.comb2_3.img}
                        references={data.diagnostic_alg.comb2_3.refs}
                        color={data.diagnostic_alg.comb2_3.color}/>
                
               
            </div>
        );
    }
    
}

export default DiagnosticAlg;