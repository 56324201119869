import React, { Component } from "react";
import data from "../shared/data";
import ImageComb1 from "./misc/ImageComb1Component";
import BreadCrumbs from "./misc/BreadCrumbsComponent";
import Banner from "./BannerComponent";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';

/* Skeletal symptoms component for route /symptoms/skeletal/ */
class SymptomsSkeletal extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Síntomas Esqueléticos'
      }
    });
  }

  render() {
    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Sintomas Esqueléticos - Enfermedad de Gaucher| Enfermedades Genéticas</title>
          <meta name="description" content="Los síntomas esqueléticos son comunes en la enfermedad de Gaucher y pueden manifestarse como enfermedad ósea, inmovilidad, dolor óseo …" />
          <meta name="keywords" content="Dolor óseo, Enfermedad ósea , Evidencia radiológica de enfermedad ósea, Crisis óseas dolorosas, Osteoporosis, Fracturas patológicas, Osteonecrosis, Deformidades" />
        </Helmet>
        <Banner
          banner={data.symptoms_skeletal.banner_img}
          parser={this.props.parser}
          msg={data.symptoms_skeletal.banner_msg}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          btn_text={data.effectbar.call_to_action.btn_text}
        />

        {/* Breadcrumbs subcomponent nav*/}
        <BreadCrumbs
          current={data.symptoms.cols.content[2].title}
          parser={this.props.parser}
          breadcrumbs={data.symptoms.breadcrumb}
        />

        <a className="anchor" id="skeletal" />

        <ImageComb1
          title={data.symptoms_skeletal.comb1_1.title}
          parser={this.props.parser}
          txt1={data.symptoms_skeletal.comb1_1.txt1}
          refs2={data.symptoms_skeletal.comb1_1.refs2}
          color={data.symptoms_skeletal.comb1_1.color}
          centerTitle={data.symptoms_skeletal.comb1_1.centerTitle}
        />
      </div>
    );
  }
}

export default SymptomsSkeletal;
