import React, { Component } from "react";
import data from "../shared/data";
import BreadCrumbs from './misc/BreadCrumbsComponent';
import Banner from "./BannerComponent";
import { FormGroup, Label, Input, Button } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet';
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay';
import parse from "html-react-parser";
import SimpleReactValidator from 'simple-react-validator';

const initialState = {
    question: '',
    firstName: '',
    lastName: '',
    documentType: '',
    documentNumber: '',
    medicRegister: '',
    phone: '',
    country: '',
    email: '',
    speciality: '',
    terms: '',
    policy: '',
    recaptcha: '',
    arr_error: [],
    token: '',
    mailSent: false,
    loading: false,
    recaptchaError: false,
    mailError: false,
    pageCount: '',
    formSuccess: false
}

let visible = [];
let errors = [];

const recaptchaRef = React.createRef();
const countryList = data.member.form.fields[7].options;

/*Member component for route /speak to a medical member/*/
class Member extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: '',
            firstName: '',
            lastName: '',
            documentType: '',
            documentNumber: '',
            medicRegister: '',
            phone: '',
            country: '',
            email: '',
            speciality: '',
            terms: '',
            policy: '',
            recaptcha: '',
            arr_error: [],
            fields: ["question", "firstName", "lastName", "documentType", "documentNumber", "medicRegister", "phone", "email", "speciality", "terms", "policy", "recaptcha"],
            isVisible: [],
            mailSent: false,
            mailError: '',
            token: '',
            loading: false,
            recaptchaError: false,
            mailError: false,
            pageCount: '',
            formSuccess: false
        }

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        // ReactGA.pageview(window.location.pathname);
        window.dataLayer.push({
            event: 'pageview',
            page: {
                url: window.location.pathname,
                title: 'Comuniquese con miembro del equipo medico'
            }
        });

        // Initialize visibilty for erros messages
        this.state.fields.map((elem, index) => {
            this.state.isVisible[elem] = false;
        });

        this.state.fields.map((elem, index) => {
            visible[elem] = this.state.isVisible[elem];
        });

    }

    handleChangeRecaptcha = (response) => {
        if (response) {
            this.setState({ token: response, recaptcha: true })

            this.state.fields.map((elem, index) => {
                if (elem == 'recaptcha') visible[elem] = true;
                else visible[elem] = this.state.isVisible[elem];
            });
            this.setState({ isVisible: visible });
        }
    }

    handleBlur = (event) => {
      const blurTarget = event.target;
      const blurName = blurTarget.name;

      if (blurName == 'email') {
        if (!this.validator.fieldValid('email')) {

          visible[blurName] = false;
          let errors = [];

          this.state.fields.map((elem, index) => {
            if (elem == blurName) {
              errors[elem] = data.member.form.fields[index].invalid;
            } else {
              errors[elem] = this.state.arr_error[elem];
            }
          });

          this.setState({ 
            isVisible: visible,
            arr_error: errors 
           });
        } 
      }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        this.setState({
            [name]: value
        });

        this.state.fields.map((elem, index) => {
            visible[elem] = this.state.isVisible[elem];
        });

        if (value == "" || value == false) {
            visible[name] = false;
            this.setState({ isVisible: visible });
        } else {
            visible[name] = true;
            this.setState({ isVisible: visible });
        }
    }

    wordCount = function(e) {
        var currentText = e.target.value;
        var characterCount = currentText.length;
        if (characterCount > 0) {
            this.setState({ pageCount: characterCount + "/500" });
        } else {
            this.setState({ pageCount: "" });
        }
    }

    numberInput = (event) => {
      var input = event.target.value;
      input = input.slice(0, 18);
      this.setState({ documentNumber: input });
    }


    validate = () => {

        let errors = [];
        let formIsValid = true;

        // Initialize errors messages
        this.state.fields.map((elem, index) => {
            errors[elem] = data.member.form.fields[index].validate;
        });

        if (!this.state.question || !this.state.firstName || !this.state.lastName || !this.state.documentType ||
            !this.state.documentNumber || !this.state.medicRegister || !this.state.phone || !this.state.speciality || 
            !this.state.email || !this.state.terms || !this.state.policy 
            || !this.state.recaptcha
        ) {
            formIsValid = false;
        }

        if (this.state.email && !this.validator.fieldValid('email')) {
          this.state.fields.map((elem, index) => {
            if (elem == 'email') {
              errors[elem] = data.member.form.fields[index].invalid;
            }
          });
          formIsValid = false;
        } 

        this.setState({ arr_error: errors });
        return formIsValid;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ mailSent: false })
        // this.setState({ formSuccess: true })
        const isValid = this.validate();

        if (isValid) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ loading: true });

            axios({
                    method: 'post',
                    url: process.env.REACT_APP_API_URL,
                    data: this.state,
                })
                .then(result => {

                    // console.log(result);
                    this.setState(initialState);
                    // Uncheck Terms and Policy
                    document.querySelectorAll("input[type='checkbox']")[0].checked = false;
                    document.querySelectorAll("input[type='checkbox']")[1].checked = false;
                    // Reset Recaptcha
                    recaptchaRef.current.reset();
                    this.setState({ loading: false });

                    // Reset the form
                    this.state.fields.map((elem, index) => {
                        this.state.isVisible[elem] = false;
                    });
                    this.state.fields.map((elem, index) => {
                        visible[elem] = this.state.isVisible[elem];
                    });
                    // Show return to user
                    this.setState({
                        mailSent: result.data.sent,
                        recaptchaError: result.data.recaptchaError,
                        mailError: result.data.mailError,
                        formSuccess: result.data.formSuccess,
                    });

                })
                .catch(error => {
                
                  // console.log(error);
                  this.setState({ loading: false });
                  this.setState({ mailError: error.message });
                });
        }
    }

    render() {

        const { isVisible } = this.state;

        return (
            <div>

            <Helmet>
              <title>Comuniquese con miembro del equipo medico - Enfermedad de Gaucher | Enfermedades Genéticas</title>
              <meta name="description" content="Si usted desea hablar con uno de nuestros miembros de nuestro equipo medico, por favor diligencie el formulario a continuación, complete todos los espacios" />
            </Helmet>
            <Banner
              banner={data.member.banner_img}
              msg={data.member.banner_msg}
              title={data.effectbar.call_to_action.title}
              content={data.effectbar.call_to_action.content}
              parser={this.props.parser}
              btn_text={data.effectbar.call_to_action.btn_text}
            />

            {/* Breadcrumbs nav for subcomponent*/}
            <BreadCrumbs current={data.member.content.title}
                parser={this.props.parser}
                breadcrumbs={data.member.breadcrumb}/>

              <LoadingOverlay
              active={this.state.loading}
              spinner
              text=''
              fadeSpeed={100}
              >
                {/* Sections*/}
                <div className="container">
                  <div>
                    {this.state.mailSent &&
                      <div className="success-msg">{parse(data.member.form.success)}</div>
                    }
                    {!this.state.mailSent && this.state.recaptchaError &&
                      <div className="error-msg">{data.member.form.recaptchaError}</div>
                    }
                    {!this.state.mailSent && this.state.mailError &&
                      <div className="error-msg">{data.member.form.mailError}</div>
                    }
                  </div>
                  {!this.state.formSuccess &&
                    <div className="member-wrapper">
                        <h2>{data.member.form.title}</h2>
                        <p className="note col-12">{data.member.form.subtitle}</p>

                        <form onSubmit={this.handleSubmit}>
                            <FormGroup className="col-12">
                                <Label for="question">* {data.member.form.fields[0].name}:</Label>
                                <Input type="textarea" id="question" name="question" value={this.state.question} rows="3" onChange={e => { this.handleChange(e); }} />
                                  <p className={`text-danger ${ isVisible["question"] ? "hidden" : ""}`}>{this.state.arr_error["question"]}</p>
                                  {/*<span className="text-danger">{this.state.pageCount}</span>*/}
                            </FormGroup>

                              <FormGroup className="col-12">
                                <Label>* {data.member.form.fields[1].name}:</Label>
                                <Input type="text" id="firstName" name="firstName" value={this.state.firstName} maxLength="50" onChange={this.handleChange} />
                                <p className={`text-danger ${ isVisible["firstName"] ? "hidden" : ""}`}>{this.state.arr_error["firstName"]}</p>
                              </FormGroup>

                              <FormGroup className="col-12">
                                <Label>* {data.member.form.fields[2].name}:</Label>
                                <Input type="text" id="lastName" name="lastName" value={this.state.lastName} maxLength="50" onChange={this.handleChange}  />
                                <p className={`text-danger ${ isVisible["lastName"] ? "hidden" : ""}`}>{this.state.arr_error["lastName"]}</p>
                              </FormGroup>

                              <FormGroup className="col-12 col-md-6">
                                <Label>* {data.member.form.fields[3].name}:</Label>
                                <Input type="select" name="documentType" id="documentType" value={this.state.documentType} onChange={this.handleChange}> 
                                  <option value="">Seleccione una opción</option>
                                  <option value="Cédula de extranjería">Cédula de extranjería</option>
                                  <option value="Cédula">Cédula</option>
                                  <option value="Pasaporte">Pasaporte</option>
                                </Input>
                                <p className={`text-danger ${ isVisible["documentType"] ? "hidden" : ""}`}>{this.state.arr_error["documentType"]}</p>
                              </FormGroup>

                              <FormGroup className="col-12 col-md-6">
                                <Label>* {data.member.form.fields[4].name}:</Label>
                                <Input type="number" id="documentNumber" name="documentNumber" value={this.state.documentNumber} onChange={e => { this.handleChange(e); this.numberInput(e); }}  />
                                <p className={`text-danger ${ isVisible["documentNumber"] ? "hidden" : ""}`}>{this.state.arr_error["documentNumber"]}</p>
                              </FormGroup>

                              <FormGroup className="col-12 col-md-6">
                                <Label>* {data.member.form.fields[5].name}:</Label>
                                <Input type="text" id="medicRegister" name="medicRegister" value={this.state.medicRegister} maxLength="18" onChange={this.handleChange}  />
                                <p className={`text-danger ${ isVisible["medicRegister"] ? "hidden" : ""}`}>{this.state.arr_error["medicRegister"]}</p>
                              </FormGroup>

                              <FormGroup className="col-12 col-md-6">
                                <Label>* {data.member.form.fields[6].name}:</Label>
                                <Input type="text" id="phone" name="phone" value={this.state.phone} maxLength="20" onChange={this.handleChange}  />
                                <p className={`text-danger ${ isVisible["phone"] ? "hidden" : ""}`}>{this.state.arr_error["phone"]}</p>
                              </FormGroup>

                              {/* <FormGroup className="col-12 col-md-6">
                                <Label>* {data.member.form.fields[7].name}:</Label>
                               <Input type="select" name="country" id="country" value={this.state.country} onChange={this.handleChange}> 
                                  <option value="">Seleccione una opción</option>
                                  {countryList.map((option) => (
                                  <option value={option.value} key={option.value}>{option.label}</option>
                                    ))}
                                </Input>
                                <p className={`text-danger ${ isVisible["country"] ? "hidden" : ""}`}>{this.state.arr_error["country"]}</p>
                              </FormGroup> */}
                              <FormGroup className="col-12">
                                <Label>* {data.member.form.fields[7].name}:</Label>
                                <Input type="text" id="email" name="email" value={this.state.email} maxLength="50" onChange={this.handleChange} onBlur={this.handleBlur}  />
                                <p className={`text-danger ${ isVisible["email"] ? "hidden" : ""}`}>{this.state.arr_error["email"]}</p>
                                {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })}
                              </FormGroup>

                              <FormGroup className="col-12">
                                <Label>* {data.member.form.fields[8].name}:</Label>
                                <Input type="text" id="speciality" name="speciality" value={this.state.speciality} maxLength="50" onChange={this.handleChange}  />
                                <p className={`text-danger ${ isVisible["speciality"] ? "hidden" : ""}`}>{this.state.arr_error["speciality"]}</p>
                              </FormGroup>

                              <FormGroup check>
                                <Label check for="terms">
                                  <Input type="checkbox" name="terms" onChange={this.handleChange} />
                                  <span>{data.member.form.fields[9].name} <a href="/terminos" target="_blank">Términos y Condiciones</a></span>
                                </Label>
                                <p className={`text-danger check-danger ${ isVisible["terms"]  ? "hidden" : "" }`}>{this.state.arr_error["terms"]}</p>
                              </FormGroup>

                              <FormGroup check className="last">
                                <Label check for="policy">
                                  <Input type="checkbox" name="policy" onChange={this.handleChange} />
                                  <span>{data.member.form.fields[10].name} <a href="/aviso-de-privacidad" target="_blank">Aviso de Privacidad</a></span>
                                </Label>
                                <p className={`text-danger check-danger ${ isVisible["policy"]  ? "hidden" : "" }`}>{this.state.arr_error["policy"]}</p>
                              </FormGroup>

                              <div className="formFooter">
                                <FormGroup className="g-recaptcha">
                                  <ReCAPTCHA 
                                      sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                                      onChange={this.handleChangeRecaptcha} hl="es-419"
                                      ref={recaptchaRef}
                                  />
                                  <p className={`text-danger ${ isVisible["recaptcha"]  ? "hidden" : "" }`}>{this.state.arr_error["recaptcha"]}</p>
                                </FormGroup>
                                <FormGroup>
                                  <Button className="btn btn-danger btn-lg"><h6>ENVIAR</h6></Button>
                                </FormGroup>
                              </div>
                      </form>
                    </div>
                  }
                </div>
              </LoadingOverlay>
     
          </div>
        );
    }
}

export default Member;