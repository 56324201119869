import React, { Component } from "react";
import ImageComb1 from "./misc/ImageComb1Component";
import ImageComb2 from "./misc/ImageComb2Component";
import Cols from "./misc/ColsComponent";
import Banner from "./BannerComponent";
import data from "../shared/data";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';
/* Management component for route /management/ */
class Management extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Manejo'
      }
    });
  }

  render() {
    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Manejo - Enfermedad de Gaucher | Enfermedades Genéticas</title>
          <meta name="description" content="En vista de la naturaleza genética de la enfermedad de Gaucher, actualmente no hay cura disponible. El objetivo final del manejo es reducir la acumulación del sustrato tóxico glucocerebrosido y otros glicolípidos ... " />
          <meta name="keywords" content="Manejo de la enfermedad de Gaucher, Terapia de reemplazo enzimático, Terapia de reducción de sustrato, Tratamiento sintomático" />
        </Helmet>
        <Banner
          banner={data.management.banner_img}
          parser={this.props.parser}
          msg={data.management.banner_msg}
          desc={data.management.banner_desc}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          btn_text={data.effectbar.call_to_action.btn_text}
        />

        <Cols
          color={data.management.cols.color}
          parser={this.props.parser}
          content={data.management.cols.content}
        />

        {/* Sections*/}
        <a className="anchor" id="management"/>
          <ImageComb1
            title={data.management.comb1_1.title}
            parser={this.props.parser}
            txt1={data.management.comb1_1.txt1}
            sub2={data.management.comb1_1.sub2}
            txt2={data.management.comb1_1.txt2}
            references={data.management.comb1_1.refs}
            color={data.management.comb1_1.color}
          />

        <a className="anchor" id="terapia_enzimatica"/>
          <ImageComb2
            content={data.management.comb2_1.content}
            parser={this.props.parser}
            img={data.management.comb2_1.img}
            references={data.management.comb2_1.refs}
            color={data.management.comb2_1.color}
            center={data.management.comb2_1.center}
          />
        <a className="anchor" id="sustrato_terapia"/>
          <ImageComb2
            content={data.management.comb2_2.content}
            parser={this.props.parser}
            img={data.management.comb2_2.img}
            references={data.management.comb2_2.refs}
            color={data.management.comb2_2.color}
            center={data.management.comb2_2.center}
          />
        <a className="anchor" id="tratamiento_sintomatico"/>
          <ImageComb1
            title={data.management.comb1_2.title}
            parser={this.props.parser}
            txt1={data.management.comb1_2.txt1}
            sub2={data.management.comb1_2.sub2}
            txt2={data.management.comb1_2.txt2}
            references={data.management.comb1_2.refs}
            color={data.management.comb1_2.color}
          />
      </div>
    );
  }
}

export default Management;
