import React, { Component } from "react";
//Image Combination Component type 2
class ImageComb2 extends Component {
  render() {
    var comp = this;
    var content, img, imgdesc, refs;

    if (this.props.content) {
      content = this.props.content.map((cont, index) => {
        return (
          <div className="col-12 col-md" key={index}>
            <h2>{comp.props.parser.parse(cont.title)}</h2>
            <p>{comp.props.parser.parse(cont.content)}</p>
          </div>
        );
      });
    }
    if (this.props.references) {
      refs = this.props.references.map((ref, index) => {
        return <li key={index}>{ref}</li>;
      });
    }
    // IMG
    img = <img src={this.props.img} className="img-fluid" alt="" />;
    // Img description
    if (this.props.imgdesc)
      imgdesc = comp.props.parser.parse(this.props.imgdesc);
    return (
      <section style={{ backgroundColor: this.props.color }}>
        <div
          className={
            "container " + (this.props.center ? "text-center" : "text-left")
          }
        >
          <div className="row pt-5 pb-5">
            {content}
            <div className="col-12">{img}</div>
            <div className="col-12">
              <p>{imgdesc}</p>
            </div>
            <div className="col-12 refs pt-5">
              <ol className="text-left">{refs}</ol>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ImageComb2;
