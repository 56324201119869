import React, { Component } from "react";
//Component for text with background
class TextBackground extends Component {
  render() {
    var comp = this;
    var title, content, msg;
    if (this.props.title)
      title = <h5 key={1}>{comp.props.parser.parse(this.props.title)}</h5>;
    content = <p key={2}>{comp.props.parser.parse(this.props.content)}</p>;
    if (title) msg = [title, content];
    else msg = [content];

    return (
      <section style={{ backgroundColor: this.props.color }}>
        <div className="container">
          <div className="row justify-content-center text-center p-5">
            <div className="col-12 align-self-center pt-5 pb-5">{msg}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default TextBackground;
