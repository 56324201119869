import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import data from "../../shared/data";

//Breadcrumbs component for subcomponent in sumptoms and diagnosis
class BreadCrumbs extends Component {
  getName(url) {
    //Function for checking user history using the given url and the links foer the breadcrumb
    for (var i in data.links) {
      if (data.links[i].link === url) return data.links[i].name;
    }
    return this.props.current;
  }

  render() {
    var comp = this;
    //Breadcrumb render
    const breadcrum = this.props.breadcrumbs.map((cont, index) => (
      <li className="breadcrumb-item " key={index}>
        <NavLink to={cont.link} exact={true}>
          {cont.name}
        </NavLink>
      </li>
    ));
    return (
      <div className="pt-5 pb-5">
        <nav aria-label="breadcrumb pt-2 pt-lg-0">
          <ol className="breadcrumb arr-right bg-light">
            {breadcrum}
            <li
              className="breadcrumb-item text-dark active"
              aria-current="page"
            >
              {comp.props.parser.parse(this.props.current)}
            </li>
          </ol>
        </nav>
      </div>
    );
  }
}

export default BreadCrumbs;
