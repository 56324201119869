import React, { Component } from "react";
import ImageComb1 from "./misc/ImageComb1Component";
import data from "../shared/data";
import Banner from "./BannerComponent";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';
/*Epidemiology component for route /epidemiology/*/
class Epidemiology extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location.pathname,
          title: 'Epidemiología'
        }
      });
  }

  render() {
    var comp = this;
    const customReferences = data.epidemiology.custom_comb.refs.map(
      (ref, index) => <li className="references white" key={index}>{ref}</li>
    );
    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Epidemiología - Enfermedad de Gaucher | Enfermedades Genéticas</title>
          <meta name="description" content="La prevalencia general de la enfermedad de Gaucher es baja, se produce con una frecuencia de aproximadamente 1 en 50,000 a 1 en 100,000 nacidos vivos .." />
          <meta name="keywords" content="Epidemiología enfermedad de Gaucher, Prevalencia de la enfermedad de Gaucher, Herencia enfermedad de Gaucher" />
        </Helmet>
        <Banner
          banner={data.epidemiology.banner_img}
          parser={this.props.parser}
          msg={data.epidemiology.banner_msg}
          desc={data.epidemiology.banner_desc}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          btn_text={data.effectbar.call_to_action.btn_text}
        />

        <a className="anchor" id="epidemiology"/>
          <ImageComb1
            title={data.epidemiology.comb1_1.title}
            parser={this.props.parser}
            txt1={data.epidemiology.comb1_1.txt1}
            refs2={data.epidemiology.comb1_1.refs2}
            color={data.epidemiology.comb1_1.color}
          />
        {/* black heritage component*/}
        <a className="anchor" id="heritage"/>

        <div className="text-left custom-banner">
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-12">
                <h3>
                  {comp.props.parser.parse(data.epidemiology.custom_comb.title)}
                </h3>
              </div>
              <div className="col-12 p-3" />
              <div className="col-12 col-md-6">
                <h6>
                  {comp.props.parser.parse(data.epidemiology.custom_comb.txt1)}
                </h6>
              </div>
              <div className="col-12 col-md-6">
                <p>
                  {comp.props.parser.parse(data.epidemiology.custom_comb.txt2)}
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  className="img-fluid"
                  src={data.epidemiology.custom_comb.img}
                  alt=""
                />
              </div>
              <div className="col-12 col-md-6">
                <p>
                  {comp.props.parser.parse(data.epidemiology.custom_comb.txt3)}
                </p>
                <ol>{customReferences}</ol>
              </div>
            </div>
          </div>
        </div>

        <a className="anchor" id="recessive"/>
          <ImageComb1
            title={data.epidemiology.comb1_2.title}
            parser={this.props.parser}
            txt1={data.epidemiology.comb1_2.txt1}
            txt2={data.epidemiology.comb1_2.txt2}
            references={data.epidemiology.comb1_2.refs}
            color={data.epidemiology.comb1_2.color}
          />
      </div>
    );
  }
}

export default Epidemiology;
