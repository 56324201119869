import React, { Component } from "react";
import data from "../shared/data";
import ImageComb1 from "./misc/ImageComb1Component";
import Banner from "./BannerComponent";
import BreadCrumbs from "./misc/BreadCrumbsComponent";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';

//Neurological symptoms for route /symptoms/neurological/ */
class SymptomsNeurological extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Síntomas Neurológicos'
      }
    });
  }

  render() {
    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Sintomas Neurológicos - Enfermedad de Gaucher| Enfermedades Genéticas</title>
          <meta name="description" content="El grado de afectación neurológica depende del tipo de enfermedad de Gaucher …" />
          <meta name="keywords" content="Compresión de la médula espinal, Comprensión de la raíces nerviosas, Neuropatía periférica, Parkinsonismo, Demencia, Hidropesía fetal grave" />
        </Helmet>
        <Banner
          banner={data.symptoms_neurological.banner_img}
          msg={data.symptoms_neurological.banner_msg}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          parser={this.props.parser}
          btn_text={data.effectbar.call_to_action.btn_text}
        />
        {/* Breadcrumbs subcomponent nav*/}
        <BreadCrumbs
          current={data.symptoms.cols.content[3].title}
          parser={this.props.parser}
          breadcrumbs={data.symptoms.breadcrumb}
        />

        <a className="anchor" id="neurological" />
        <ImageComb1
          title={data.symptoms_neurological.comb1_1.title}
          parser={this.props.parser}
          txt1={data.symptoms_neurological.comb1_1.txt1}
          txt2={data.symptoms_neurological.comb1_1.txt2}
          references={data.symptoms_neurological.comb1_1.refs}
          color={data.symptoms_neurological.comb1_1.color}
          centerTitle={data.symptoms_neurological.comb1_1.centerTitle}
        />

      </div>
    );
  }
}

export default SymptomsNeurological;
