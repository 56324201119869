import React, { Component } from "react";
import data from "../shared/data";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

/*HCP VERIFICATION Component*/
class HCPVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHCP: null,
      redirectToReferrer: false
    };

    this.handleIsHCP = this.handleIsHCP.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname
      }
    });
  }

  handleIsHCP(value) {
    // Set whether the person is a HCP or not
    this.setState({
      isHCP: value
    });
  }

  handleSubmit(event) {
    // Set in localStorage that the person is a HCP
    event.preventDefault();
    if (this.state.isHCP === null)
      return;
    localStorage.setItem("isHCP", this.state.isHCP);
    this.setState({
      redirectToReferrer: true
    });
  }

  render() {
    const redirectToReferrer = this.state.redirectToReferrer;

    var storage = localStorage.getItem("isHCP");
    //If user has already accepted go to home
    if (storage === true) {
      // console.log("Hanel components");
      this.props.handleComponents();
      this.props.history.push("/");
    }
    if (redirectToReferrer === true) {
      //If user has consented to be an HCP go to home
      if (this.state.isHCP) {
        // console.log("Handle components");
        this.props.handleComponents();
        this.props.history.push("/");
      } else {
        //Else go to fallback direction
        window.location.href = data.hcp_ver.fallback_link;
      }
    }
    // Map all the text
    const text = data.hcp_ver.txt.map((txt, ind) => <p key={ind}>{txt}</p>);
    return (
      // Background image
      <div
        className="container-fluid hcp_ver text-left"
        style={{ backgroundImage: "url(/img/hcp_ver.jpg)" }}
      >
        <div className="row mb-auto h-100 justify-content-center align-items-center">
          {/* Create form*/}
          <form
            className="col-12 col-md-6 overlay p-5"
            onSubmit={this.handleSubmit}
          >
            {/* Announcement text*/}
            {text}
            {/* Checkboxes*/}
            <div className="form-check">
              <input
                className="form-check-input"
                name="isHCP"
                type="checkbox"
                checked={this.state.isHCP}
                onChange={() => this.handleIsHCP(true)}
                id="defaultCheck1"
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                {data.hcp_ver.affirmative}
              </label>
            </div>
            <div className="form-check pb-3">
              <input
                className="form-check-input"
                name="isHCP"
                type="checkbox"
                checked={this.state.isHCP === false}
                onChange={() => this.handleIsHCP(false)}
                id="defaultCheck2"
              />
              <label className="form-check-label" htmlFor="defaultCheck2">
                {data.hcp_ver.negative}
              </label>
            </div>
            {this.state.isHCP !== null ? <div></div> :

              <div>
                <p className="text-danger">{data.hcp_ver.checkText}</p>
              </div>
            }

            {/* Submit*/}
            <button className={"btn btn-danger " + (this.state.isHCP === null ? 'disabled' : '')}>
              {data.hcp_ver.submit_msg}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(HCPVerification);
