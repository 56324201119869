import React, { Component } from "react";

//Columns component
class Cols extends Component {
  render() {
    var comp = this;
    //Content of each column mapped as an array
    const content = this.props.content.map((cont, index) => {
      return (
        <div className="col-12 col-sm-6 col-lg pt-2" key={index}>
          <h2 className={comp.props.parser.parse(cont.class)}>{comp.props.parser.parse(cont.title)}</h2>
          <p>{comp.props.parser.parse(cont.desc)}</p>
          <div className="row">
            <div className="col-8 offset-2">
              <a href={cont.link}>
                <button className="btn btn-danger btn-lg btn-block">
                  <h6>{cont.cta}</h6>
                </button>
              </a>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div
        className="container-fluid"
        style={{ backgroundColor: this.props.color }}
      >
        <div className="row p-5 justify-content-center">{content}</div>
      </div>
    );
  }
}

export default Cols;
