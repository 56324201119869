import React, { Component } from "react";
import data from "../shared/data";
import Banner from "./BannerComponent";
import BreadCrumbs from "./misc/BreadCrumbsComponent";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';
import parse from "html-react-parser";
//DBS Kits Component corresponding to route /diagnosis/dbs-kits
class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Aviso de privacidad'
      }
    });
  }

  render() {
    //Terms and Conditions content
    const content = data.privacy_policy.content.map(cont => (
      <div
        key={cont.id}
        className="pt-5 pb-5 col-12"
        style={{ border: "1px solid transparent", borderBottomColor: "grey" }}
      >
        {/* Id for anchors*/}
        <a className="anchor" id={cont.id} />
        {/* Actual content*/}
        <h4>{cont.title}</h4>
        {cont.txt.map((txt, ind) => (
          <p className="policy_content" key={ind}>{parse(txt)}</p>
        ))}
      </div>
    ));

    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Aviso de privacidad - Enfermedad de Gaucher | Enfermedades Genéticas</title>
          <meta name="description" content="El Portal web Portal Enfermedad de Gaucher (en adelante el “Portal”) de propiedad de Baxalta S.A.S y administrado por Baxalta S.A.S …" />
        </Helmet>
        <Banner
          banner={data.privacy_policy.banner_img}
          parser={this.props.parser}
          msg={data.privacy_policy.banner_msg}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          btn_text={data.effectbar.call_to_action.btn_text}
        />
        {/* breadcrumbs for subcomponent navigation*/}
        <BreadCrumbs
          current={data.privacy_policy.current_breadcrumb}
          parser={this.props.parser}
          breadcrumbs={data.privacy_policy.breadcrumb}
        />

        {/* Terms description*/}
        <div className="container text-left">
          <div className="row">
            <div className="col-12">
              <h2>{data.privacy_policy.title}</h2>
              <p>{data.privacy_policy.date}</p>
            </div>
            {content}
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
