import React, { Component } from "react";
import data from "../shared/data";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NavLink } from "react-router-dom";
import ReactGA from "react-ga";
import cookiePolicy from "../static/cookies-policy.pdf";

/*Component for Cookies, Social Media and Share Modal*/
class FloatingBars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookies: true
    };
    this.shareModal = React.createRef();
    this.verifyCookies = this.verifyCookies.bind(this);
    this.socialMediaOpenShare = this.socialMediaOpenShare.bind(this);
    this.socialMediaLeave = this.socialMediaLeave.bind(this);
    this.socialMediaStay = this.socialMediaStay.bind(this);
  }

  verifyCookies() {
    /*Stop cookie popup*/
    this.setState({
      cookies: false
    });
    localStorage.setItem("cookieVerification", 1);
  }

  socialMediaOpenShare(id) {
    // Set social media share
    this.setState({
      show: true,
      sharetype: id
    });
  }

  socialMediaLeave() {
    //Action after acepting to leave the site
    var sharetype = this.state.sharetype;

    // check different social media and generate messages
    if (sharetype === "twitter") {
      var text = "Si quiere conocer más acerca de la Enfermedad de Gaucher lo invitamos a visitar https://www.enfermedaddegaucher.com.co";

      var url = window.location.href;
      ReactGA.event({
        category: "Share",
        action: "click",
        label: "Twitter"
      });
      window.open(
        "http://twitter.com/share?url=" +
        encodeURIComponent(url) +
        "&text=" +
        encodeURIComponent(text),
        "_blank"
      );
    } else if (sharetype === "linkedin") {
      ReactGA.event({
        category: "Share",
        action: "click",
        label: "LinkedIn"
      });
      //ga('send', 'event', 'Share', 'click', 'LinkedIn');
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=https%3A//www.enfermedaddegaucher.com.co&title=Enfermedaddegaucher.com.co&summary=&source=",
        "_blank"
      );
    } else {
      ReactGA.event({
        category: "Share",
        action: "click",
        label: "Facebook"
      });
      // ga('send', 'event', 'Share', 'click', 'Facebook');
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=https%3A//www.enfermedaddegaucher.com.co",
        "_blank"
      );
    }
    this.setState({
      show: false
    });
    return false;
  }

  socialMediaStay() {
    //Action after accepting to stay on the site
    this.setState({
      show: false
    });
  }

  componentDidMount() {
    //Check if cookies should be rendered
    var cookieContinue = localStorage.getItem("cookieVerification");
    if (cookieContinue != null && cookieContinue == 1) {
      this.setState({
        cookies: false,
        show: false
      });
    }
  }

  render() {
    //Social media share modal
    var shareModal = (
      <Modal isOpen={this.state.show} toggle={this.socialMediaStay}>
        <ModalHeader toggle={this.socialMediaStay}>
          {data.share.title}
        </ModalHeader>
        <ModalBody>{data.share.text}</ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={this.socialMediaStay}>
            {data.share.cancel}
          </Button>
          <Button variant="primary" onClick={this.socialMediaLeave}>
            {data.share.accept}
          </Button>
        </ModalFooter>
      </Modal>
    );
    //Social media icons
    var socialMedia = (
      <div className="icon-bar">
        <a
          href="javascript:;"
          id="linkedin-click"
          onClick={() => this.socialMediaOpenShare("linkedin")}
          className="linkedin"
        >
          <i className="fab fa-linkedin-in" />
        </a>
        <a
          href="javascript:;"
          id="twitter-click"
          onClick={() => this.socialMediaOpenShare("twitter")}
          className="twitter"
        >
          <i className="fab fa-twitter" />
        </a>
        <a
          href="javascript:;"
          onClick={() => this.socialMediaOpenShare()}
          className="facebook"
        >
          <i className="fab fa-facebook-square" />
        </a>
        <a
          href="mailto:?subject=Enfermedaddegaucher.com.co&body=Si quiere conocer más acerca de la Enfermedad de Gaucher lo invitamos a visitar https://www.enfermedaddegaucher.com.co "
          className="mail"
        >
          <i className="fas fa-envelope" />
        </a>
      </div>
    );
    //Cookies
    var cookies = (
        <div className="row no-gutters cookie-popup">
          <div className="col-12 col-md-10 align-self-center">
            <p style={{textAlign: 'left'}}>{data.cookies.text}</p>
          </div>
          <div className="col-12 col-md-2 align-self-center">
            <div className="btn-group btn-group-lg" role="group">
              <button type="button" className="btn btn-success">
                <a href={cookiePolicy} target="_blank">{data.cookies.knowText}</a>
              </button>
              <button
                type="button"
                className="btn btn-dark"
                onClick={this.verifyCookies}
              >
                {data.cookies.acceptText}
              </button>
            </div>
          </div>
        </div>
    );

    // Diagnosis test
    var diagnosisTest = (
        <div id="floatingbox">
          <NavLink to="/comuniquese-con-miembro-del-equipo-medico/">
            {data.diagnosis_test.callback_button}
          </NavLink>
        </div>
    );

    // Render everything
    return (
      <div>
        {/* socialMedia */}
        <div className="container-cookie-wrapper text-center">
          {diagnosisTest}
          { this.state.cookies ? cookies : null }
          { /*shareModal */ }
        </div>
      </div>
    );
  }
}

export default FloatingBars;
