const data = {
    "anprom": {
        "anprom": "C-ANPROM/CO//0058"
    },
    "hcp_ver": {
        "img": "/img/hcp_ver.jpg",
        "affirmative": "Confirmo que soy profesional de la salud en Colombia",
        "negative": "No soy un profesional de la salud",
        "checkText": "Por favor seleccione una de las opciones",
        "txt": [
            "La información de este sitio web está dirigida exclusivamente a profesionales de la salud en Colombia.",
            "Por favor, confirme que es un profesional de la salud en Colombia."
        ],
        "submit_msg": "Enviar >",
        "fallback_link": "https://www.Takeda.com.co"
    },
    "share": {
        "title": "Compartir contenido",
        "text": "Estás saliendo de este sitio. No revisamos ni controlamos el contenido de sitios web externos, y este hipervínculo no constituye una aprobación del contenido del sitio.",
        "accept": "OK",
        "cancel": "Cancelar"
    },
    "cookies": {
        "text": "Este sitio utiliza cookies para proporcionarle un servicio más personalizado y receptivo. Al utilizar este sitio, usted acepta nuestro uso de cookies como se establece en nuestro aviso sobre cookies. Lea nuestro aviso sobre el uso de cookies para obtener más información sobre las cookies que utilizamos y sobre cómo eliminar o bloquear el uso de cookies.",
        "knowText": "Saber más",
        "knowLink": "https://www.takeda.com/es-co/disclaimer/privacy-notice/",
        "acceptText": "OK"
    },
    "effectbar": {
        "logo": "/img/logo_black.png",
        "call_to_action": {
            "title": "¿Quiere estar al día?",
            "content": "Regístrese para recibir las últimas noticias relacionadas con Gaucher.",
            "btn_text": "REGÍSTRESE HOY>"
        },
        "links": [
            {
                "name": "Inicio",
                "link": "/"
            },
            {
                "name": "Síntomas",
                "link": "/sintomas",
                "children": [
                    {
                        "name": "Síntomas hematológicos",
                        "link": "/sintomas/hematologicos"
                    },
                    {
                        "name": "Síntomas esqueléticos",
                        "link": "/sintomas/esqueleticos"
                    },
                    {
                        "name": "Síntomas neurológicos",
                        "link": "/sintomas/neurologicos"
                    }
                ]
            },
            {
                "name": "Diagnóstico",
                "link": "/diagnostico",
                "children": [
                    {
                        "name": "Algoritmos de diagnóstico",
                        "link": "/diagnostico/diagnostico-algoritmos"
                    },
                    {
                        "name": "Importancia del diagnóstico precoz",
                        "link": "/diagnostico/importancia-diagnostico-temprano"
                    },
                    {
                         "name": "Prueba de diagnóstico",
                         "link": "/diagnostico/prueba-diagnostico"
                    }
                ]
            },
            {
                "name": "Manejo",
                "link": "/manejo"
            },
            {
                "name": "Asesoría Genética",
                "link": "/asesoria-genetica"
            },
            {
                "name": "Epidemiología",
                "link": "/epidemiologia"
            }
        ]
    },
    "footer": {
        "checkmark_description": "Con su autorización, Takeda desea comunicarse con usted por medios electrónicos (como correo electrónico o mdS) con información sobre productos y servicios que creemos pueden ser de su interés. Tiene derecho a optar por no recibir dichas comunicaciones electrónicas, en cualquier momento, poniéndose en contacto con nosotros. Dependiendo del país en el que resida, es posible que la ley exija a Takeda que obtenga su consentimiento para poder enviarle dichas comunicaciones electrónicas.",
        "checkmark_1": "Doy mi consentimiento para ser contactado por Takeda por medios electrónicos con información sobre los productos y servicios de Takeda. Te animamos a leer nuestra ",
        "link_1": "política de privacidad",
        "checkmark_2": "He leído y acepto ",
        "link_2": "los Términos de Uso",
        "title": "La información de este sitio web está dirigida exclusivamente a profesionales de la salud habilitados para ejercer la profesión en Colombia",
        "description": [
            "Enfermedaddegaucher.com.co está dirigido exclusivamente a los profesionales de la salud autorizados para ejercer su profesión en Colombia. " + 
            "El sitio web ha sido desarrollado y financiado por Takeda de conformidad con los estándares legales y de la industria para proporcionar " + 
            "información general sobre temas de salud relacionados con la enfermedad de Gaucher. Esta información está a disposición de los profesionales " + 
            "de la salud con fines informativos únicamente. No tiene como fin reemplazar ni sustituir el criterio profesional del médico. Takeda hace todos " + 
            "los esfuerzos razonables para incluir información precisa y actualizada. No obstante, la información proporcionada en el sitio web no es " + 
            "exhaustiva, por lo que Takeda no asume responsabilidad alguna en el evento en que se presente alguna imprecisión o que la información no se " + 
            "encuentre actualizada y/o completa. Información de contacto: Baxalta Colombia S.A.S., Calle 116 # 7-15, Piso 7, Edificio Cusezar – Bogotá D.C. " + 
            "Colombia. Teléfono: +57 (1) 6393600. Para el reporte de eventos adversos u otra información de seguridad relacionada con nuestros productos, " + 
            "por favor reportarlo al siguiente correo: ae.brasam@takeda.com."
        ],
        "disclaimer": "El uso de todas las imágenes y testimonios de pacientes en este sitio web está autorizado. En ningún caso usted estará autorizado para copiar o usar de cualquier modo dichas imágenes o testimonios.",
        "disclaimer_2": "Todas las marcas registradas son propiedad de sus respectivos titulares y su uso por parte de terceros se encuentra expresamente prohibido. Takeda se reserva todos los derechos de autor. Se prohíbe cualquier reproducción o uso, total o parcial, sin la previa y expresa autorización por escrito de Takeda.",
        "legal_statement": "Términos y Condiciones",
        "privacy_policy": "Aviso de privacidad",
        "cookies_information": "Información de Cookies",
        "logo": "/img/takeda-logo.png"
    },
    "diagnosis_test": {
        "callback_button": "¿Desea contactar al equipo de Información Médica?",
        "banner_img": "/img/banner_home.jpg",
        "banner_msg": "Prueba de diagnóstico",
        "step1": {
            "img": "/img/Diagnosis_test_Step01.png",
            "header": "1. Identificar al paciente",
            "text": "Identificar al Paciente con posible Diagnóstico",
            "ctaText": "Ver síntomas",
            "ctaLink": "/sintomas"
        },
        "step2": {
            "img": "/img/Diagnosis_test_Step02.png",
            "header": "2. Confirmar orden",
            "tests": [
                {
                    "title": "Actividad enzimática para glucocerebrosidasa",
                    "text": [
                        "Medición de niveles enzimáticos de glucocerebrosidasa en gota de sangre seca en papel de filtro (DBS)"
                    ]
                },
                {
                    "title": "Prueba molecular para Enfermedad de Gaucher",
                    "text": [
                        "Muestra de gota de sangre seca en papel de filtro (DBS) para secuenciación del gen GBA"
                    ]
                },
                {
                    "title": "Lyso Gb1",
                    "text": [
                        "Muestra de gota de sangre seca en papel de filtro (DBS) para medición de niveles de glicosilesfingosina (Lyso Gb1) como biomarcador específico para Enfermedad de Gaucher (seguimiento de eficacia terapéutica)"
                    ]
                }
            ]
        },
        "step3": {
            "img": "/img/Diagnosis_test_Step03.png",
            "header": "3. Puntos de contacto",
            "text": "Contáctese con nuestro personal si tiene alguna duda o inquietud",
            "contacts": [
                // {
                //     "icon": "/svg/phone-call.svg",
                //     "color": "rgb(40,167,69)",
                //     "size": 6,
                //     "iconSizeSm": 1,
                //     "iconSizeLg": 4,
                //     "cta": [
                //         {
                //             // "text": "+57 (1) 257 9332",
                //             // "link": "tel:+570312579332"
                //         }
                //     ]
                // },
                {
                    "icon": "/svg/cellphone.svg",
                    "color": "rgb(40,167,69)",
                    "size": 6,
                    "iconSizeSm": 1,
                    "iconSizeLg": 4,
                    "cta": [
                        {
                            "text": "315 344 5155",
                            "link": "tel:+573153445155"
                        },
                        {
                            "text": "310 860 1142",
                            "link": "tel:+573108601142"
                        }
                    ]
                },
                {
                    "icon": "/svg/mail.svg",
                    "color": "#42A7F4",
                    "size": 12,
                    "iconSizeSm": 1,
                    "iconSizeLg": 2,
                    "cta": [
                        {
                            "text": "dx.bienestar@laboratorioclinicobiolab.com",
                            "link": "mailto:dx.bienestar@laboratorioclinicobiolab.com"
                        }
                    ]
                }
            ]
        },
        "step4": {
            "img": "/img/Diagnosis_test_Step04.png",
            "header": "4. Resultados Médicos",
            "text": "El profesional de la salud recibirá vía portal web los resultados clínicos de la prueba diagnóstica. Si tiene alguna duda, comuníquese con alguno de los puntos de contacto del paso 3."
        },
        "disclaimer_text": "Tanto la solicitud de pruebas diagnósticas como la entrega de resultados se realiza por medio de nuestros laboratorios aliados. Takeda no está involucrado en la realización de pruebas, análisis, ni suministro de resultados a fin de evitar influenciar su decisión de tratamiento."
    },
    "home": {
        "banner_img": "/img/banner_home.jpg",
        "banner_msg": "Entendiendo la enfermedad de Gaucher",
        "parallax": {
            "title": "Tipos 1,2 y 3",
            "content": "Hay tres subtipos de enfermedad de Gaucher que se clasifican por la presencia o ausencia de compromiso neurológico, y por la tasa de progresión de la enfermedad y el grado de severidad. <sup>1-4</sup>"
        },
        "banner1": {
            "title": "El propósito de este sitio es proporcionar una introducción completa a la enfermedad de Gaucher, describiendo qué es, cómo ocurre y los signos y síntomas típicos asociados con la enfermedad.",
            "content": "También se ha incluido información para ayudar en el proceso de referir los pacientes con un diagnóstico positivo de enfermedad de Gaucher. Sin embargo, el sitio no está destinado para fines de diagnóstico, y todos los diagnósticos deben ser realizados por un profesional de la salud apropiado.",
            "color": "rgb(247,247,247)"
        },
        "comb1_1": {
            "title": "Descripción general de la enfermedad de Gaucher",
            "sub1": "La enfermedad de Gaucher es un trastorno crónico progresivo asociado con discapacidad, reducción de la esperanza de vida y, en algunos casos, complicaciones que ponen en peligro la vida. <sup>1,2</sup>",
            "txt1": [
                "La enfermedad de Gaucher es una esfingolipidosis, en la que la enzima β-glucocerebrosidasa es inadecuada, ausente o disfuncional, lo que resulta en la acumulación del sustrato, glucocerebrosido en los macrófagos. <sup>3</sup> Los macrófagos rellenos de lípidos, cuya apariencia se caracteriza por el núcleo desviado hacia un lado, se llaman células de Gaucher. <sup>4</sup> Las células de Gaucher desplazan a las células normales en varios órganos afectados lo que afecta su función normal, incluyendo el bazo, el hígado, los huesos, la médula ósea, en ocasiones el cerebro y el sistema nervioso y, en casos poco frecuentes, los pulmones."
            ],
            "img": "/img/gaucher_disease_macrophage.png",
            "refs": [
                "WEINREB, NJ. et al. Am J Hematol 2008; 83(12): 896–900.",
                "GRABOWSKI, GA. Lancet 2008; 372: 1263–71.",
                "MEHTA A. Eur J Int Med. 2006; 17:S2–S5.",
                "PENNELLI N, et al. Blood 1969; 34:331–47."
            ],
            "color": "rgb(255,255,255)"
        },
        "comb1_2": {
            "title": "Confirmando un diagnóstico de enfermedad de Gaucher",
            "sub1": "La enfermedad de Gaucher es causada por una deficiencia de la enzima lisosomal, glucocerebrosidasa, lo que produce la acumulación de su sustrato principal, la glucosilceramida y conduce a una disfunción celular. <sup>1</sup>",
            "txt1": [
                "El glucocerebrosido se acumula principalmente en los macrófagos. <sup>2</sup> Estas células se encuentran en el sistema reticuloendotelial, que incluye el sistema fagocítico mononuclear, las células que recubren los sinusoides del bazo, los ganglios linfáticos y la médula ósea, y las células reticulares fibroblásticas de los tejidos hematopoyéticos. <sup>2</sup> La enfermedad de Gaucher a veces se conoce como una \"enfermedad reticuloendotelial\" debido a la acumulación de glucocerebrosido en los macrófagos del sistema reticuloendotelial."
            ],
            "img": "/img/diagram_8_ceramide_glucose.png",
            "refs": [
                "GRABOWSKI, GA.Lancet 2008; 372: 1263–71.",
                "PENNELLI N, et al. Blood 1969; 34:331–47."
            ],
            "color": "rgb(247,247,247)"
        },
        "comb2_1": {
            "content": [
                {
                    "title": "Tipo 1",
                    "content": "la forma más común, y no es neuronopática, representa más del 90% de todos los pacientes con enfermedad de Gaucher. <sup>5</sup>"
                },
                {
                    "title": "Tipo 2",
                    "content": "una forma neuronopática aguda de inicio temprano y curso rápido y degenerativo que resulta en la muerte generalmente dentro de los primeros 2 años de vida."
                },
                {
                    "title": "Tipo 3",
                    "content": "una forma neuronopática crónica con un curso menos rápido y síntomas más leves que el Tipo 2 que ocasiona la muerte en la infancia hasta la edad adulta temprana"
                }
            ],
            "img": "/img/diagram_9-type123.png",
            "refs": [
                "SIDRANSKY, E. Mol Gen Met 2004; 83: 6−15.",
                "GRABOWSKI, GA. Lancet 2008; 372: 1263–71.",
                "NAGRAL A. J Clin Exp Hepatol. 2014; 4:37–50.",
                "HOPKIN RJ et al. (Chapter) Fauci AS et al. Harrison’s Principles of Internal Medicine.",
                "MISTRY, P. et al. Am J Hematol 2011; 86(1): 110–5."
            ],
            "color": "rgb(255,255,255)"
        }
    },
    "symptoms": {
        "breadcrumb": [
            {
                "link": "/",
                "name": "Inicio"
            },
            {
                "link": "/symptoms/",
                "name": "Síntomas"
            }
        ],
        "banner_img": "/img/banner_symptoms.jpg",
        "banner_msg": "Síntomas",
        "cols": {
            "color": "rgb(255,255,255)",
            "content": [
                {
                    "title": "Visceral",
                    "desc": "Síntomas viscerales asociados a la enfermedad de Gaucher",
                    "cta": "LEER MÁS >",
                    "link": "#visceral"
                },
                {
                    "title": "Hematológico",
                    "desc": "Síntomas hematológicos asociados a la enfermedad de Gaucher",
                    "cta": "LEER MÁS >",
                    "link": "/sintomas/hematologicos"
                },
                {
                    "title": "Esquelético",
                    "desc": "Síntomas esqueléticos asociados con la enfermedad de Gaucher",
                    "cta": "LEER MÁS >",
                    "link": "/sintomas/esqueleticos"
                },
                {
                    "title": "Neurológico",
                    "desc": "Síntomas neurológicos asociados a la enfermedad de Gaucher.",
                    "cta": "LEER MÁS >",
                    "link": "/sintomas/neurologicos"
                }
            ]
        },
        "comb1_1": {
            "title": "Síntomas",
            "sub1": "La presentación clínica de la enfermedad de Gaucher es muy variable y heterogénea. Las células de Gaucher llenas de lípidos desplazan a las células normales en los órganos afectados, como el bazo, el hígado, los huesos, la médula ósea, en ocasiones el cerebro y el sistema nervioso y, en casos raros, los pulmones. <sup>4-9</sup>",
            "txt1": [
                "La acumulación de células de Gaucher puede causar agrandamiento del bazo y el hígado y, posteriormente, interferir con su función. Además, las células de Gaucher pueden causar deterioro de la médula ósea, anemia y trombocitopenia, tejido óseo comprometido que puede dar lugar a fracturas y dolor, afectación del sistema nervioso y, en algunos casos, hipertensión pulmonar poco frecuente y potencialmente mortal. <sup>4,7,9</sup> De esta manera, la enfermedad de Gaucher es un trastorno multisistémico."
            ],
            "img": "/img/diagram_11_body.png",
            "imgtitle": "La afectación pulmonar generalmente no se observa en el momento del diagnóstico; solo el 1-2% de los pacientes con enfermedad de Gaucher tipo 1 exhiben manifestaciones pulmonares que se presentan en forma de enfermedad pulmonar intersticial o enfermedad vascular pulmonar.",
            "imgdesc": [
                "NÓTESE BIEN. Estos valores representan los síntomas presentes al momento del diagnóstico según los datos de 4,936 pacientes del registro del International Collaborative Gaucher Group (ICGG), de los cuales el 92% tenía enfermedad tipo 1. <sup>3</sup> Los porcentajes representan la proporción de pacientes con cada síntoma en el momento del diagnóstico. <sup>3</sup>",
                "* No se ve generalmente en el momento del diagnóstico; solo 1 a 2% de los pacientes con enfermedad de Gaucher tipo 1 exhiben manifestaciones pulmonares que se presentan en forma de enfermedad pulmonar intersticial o enfermedad vascular pulmonar.",
                "** En las enfermedades de Gaucher tipos 2 y 3 únicamente."
            ],
            "refs": [
                "PASTORES, GM. et al. Semin Hematol 2004; 41(Suppl 5): 4−14.",
                "INTERNATIONAL COLLABORATIVE GAUCHER GROUP (ICGG). Gaucher Registry Annual Report 2008. Available at: https://www.gauchercare.com/healthcare/registry.aspx Accessed: 12/05/16.",
                "MISTRY, P. et al. Am J Hematol 2007; 82(8): 697–701.",
                "MEHTA, A. Eur J Int Med. 2006;17:S2–S5.",
                "PENNELLI, N et al. Blood. 1969;34:331–47.",
                "GRABOWSKI, GA. Hematology Am Soc Hematol Educ Program. 2012:13–8.",
                "NAGRAL, A. J Clin Exp Hepatol. 2014; 4:37–50.",
                "HOPKIN, RJ and, GRABOWSKI, GA. Chapter 355. Lysosomal Storage Diseases (Chapter). Fauci AS, Braunwald E, Kasper DL, Hauser SL, Longo DL, Jameson JL, Loscalzo J. Harrison’s Principles of Internal Medicine.",
                "KOLTER, T and SANDHOFF, K. Biochimica et Biophysica Acta.2006;1758:2057–79."
            ],
            "color": "rgb(247,247,247)"
        },
        "comb1_2": {
            "title": "Hepatoesplenomegalia",
            "txt1": [
                "La hepatoesplenomegalia (agrandamiento del hígado y el bazo) ocurre en casi todos los pacientes sintomáticos y puede ocurrir en mayor medida en pacientes más jóvenes que también tienen un mayor grado de citopenias.<sup>1,2</sup> La esplenomegalia suele ser la primera presentación clínica; el bazo, que puede agrandarse hasta 75 veces, está más agrandado que el hígado y puede agrandarse hasta tres veces. <sup>3</sup> La hepatosplenomegalia a menudo conduce a una distensión abdominal masiva con anemia y trombocitopenia."
            ],
            "imgdesc": [
                "Imagen tomada de: NAGRAL A. Gaucher disease. J Clin Exp Hepatol. 2014; 4(1): 37–50."
            ],
            "img": "/img/gaucher_multi_system_disorder.jpg",
            "refs": [
                "MEHTA, A. Epidemiology and natural history of Gaucher’s disease. Eur J Int Med. 2006;17:S2–S5.",
                "HOPKIN, RJ and, GRABOWSKI, GA. Chapter 355. Lysosomal Storage Diseases (Chapter). Fauci AS, Braunwald E, Kasper DL, Hauser SL, Longo DL, Jameson JL, Loscalzo J. Harrison’s Principles of Internal Medicine",
                "NAGRAL A. Gaucher disease. J Clin Exp Hepatol. 2014; 4(1): 37–50."
            ],
            "color": "rgb(255,255,255)"
        },
        "vid1": {
            "vid": "https://www.youtube.com/embed/F7NM5Co6bwI",
            "viddesc": "Earlier Diagnosis Strategies: A Non-Haematological Perspective – Dr Jordi Pérez-López",
            "color": "rgb(247,247,247)"
        }
    },
    "symptoms_hematological": {
        "custom_comb": {
            "title": "Signos y síntomas hematológicos de la enfermedad de Gaucher",
            "sub1": "Las características clínicas en el diagnóstico de la enfermedad de Gaucher con frecuencia incluyen anomalías hematológicas, de hecho, en un estudio, un 75% de los pacientes con enfermedad de Gaucher fueron diagnosticados por un Hematólogo. <sup>4</sup> Las características hematológicas de la enfermedad de Gaucher incluyen leucopenia y trastornos de la coagulación, pero las más comunes son la anemia y la trombocitopenia. <sup>5</sup>",
            "txt1": "La anemia puede ocurrir como resultado de un aumento en la función hemolítica del bazo, hemodilución, deficiencia de hierro o vitamina B12 y puede ser causa de fatiga, disnea de esfuerzo y la necesidad de transfusiones de sangre. <sup>6</sup> En la enfermedad avanzada, la anemia puede ocurrir debido a la disminución de la eritropoyesis como resultado de la insuficiencia de la médula ósea debido a la infiltración de las células de Gaucher o del infarto medular (hueso) (es decir, osteonecrosis; donde se interrumpe el suministro de sangre a una sección del hueso, lo que ocasiona isquemia y necrosis). <sup>7,8</sup>",
            "img": "/img/diagram_2_hema_involv.png",
            "imgdesc": "Los mecanismos subyacentes a las manifestaciones hematológicas observadas en la enfermedad de Gaucher suelen atribuirse al hiperesplenismo y al deterioro hematopoyético.",
            "refs1": [
                "LECOURT, S. et al. PLoS One 2013; 8(7): e69293.",
                "BERGER, J. et al. Br J Haematol 2010; 150: 93–101.",
                " SIMPSON, WL. et al. World J Radiol 2014; 6(9): 657–68",
                "THOMAS, AS. et al. Blood Cells Mol Dis 2013; 50(3): 212–7.",
                "HUGHES, D. et al. Br J Haem 2007; 138(6): 676–86"
            ],
            "refs2": [
                "NAGRAL, A. J Clin Exp Hepatol. 2014; 4:37–50.",
                "PASTORES, GM and Hughes, DA. 2000 <sup>Updated 2015</sup>. In: PAGONRA, et al. editors GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993–2015. http://www.ncbi.nlm.nih.gov/books/NBK1269/. Accessed July 2015.",
                "THOMAS AS, et al. Br J Haematol. 2014; 165(4):427–40."
            ],
            "color": "rgb(255,255,255)"
        },
        "comb1_1": {
            "title": "Trombocitopenia",
            "txt1": [
                "La trombocitopenia se define como un recuento de plaquetas de &lt;120 x 10 9 / L (rango normal: 150–440 x 10 9 / L) <sup>1,2</sup>",
                "Es común en adultos y niños con enfermedad de Gaucher, lo que ocurre en aproximadamente el 60% de los pacientes en el momento del diagnóstico <sup>3</sup>",
                "Puede ocurrir a pesar de que los niveles de hemoglobina estén dentro del rango normal y en ausencia de esplenomegalia <sup>4,5</sup>",
                "La trombocitopenia a menudo se incluye en los algoritmos de detección de la enfermedad de Gaucher <sup>6,7</sup>"
            ],
            "refs2": [
                "1. FAIRLEY, C. et al. J Inherit Metab Dis 2008; 31: 738–44.",
                "2. WHO. The Clinical Use Of Blood Handbook. Available at: http://www.who.int/bloodsafety/clinical_use/en/Handbook_EN.pdf?ua=1. 2002 Accessed: 28/09/15.",
                "3. INTERNATIONAL COLLABORATIVE GAUCHER GROUP (ICGG). Gaucher Registry Annual Report 2008. Available at: https://www.gauchercare.com/healthcare/registry.aspx Accessed: 12/05/16.",
                "4. ZIMRAN, A. et al. Hematology 2005; 10(2): 151–6.",
                "5. ROSENBLOOM, B. et al. Blood 2011; 118(21): 4217 (Abstract from ASH 2011).",
                "6. MISTRY, P. et al. Am J Hematol 2011; 86(1): 110–5.",
                "7. DI ROCCO, M. et al. Pediatr Blood Cancer 2014; 61(11): 1905–9."
            ],
            "color": "rgb(247,247,247)"
        },
        "comb1_2": {
            "title": "Anemia",
            "txt1": [
                "La anemia se define como niveles de hemoglobina &lt;11 g / dL en mujeres y &lt;12 g / dL en hombres (rango normal: 12–16 g / dL en mujeres y 14–17 g / dL en hombres) <sup>1,2</sup>",
                "No se ve con tanta frecuencia como la trombocitopenia, pero ocurre en aproximadamente el 37% de los pacientes en el momento del diagnóstico <sup>3</sup>",
                "En pacientes que presentan anemia en ausencia de trombocitopenia, es muy probable que la anemia se deba a una etiología no relacionada con Gaucher <sup>4</sup>"
            ],
            "refs2": [
                "1. FAIRLEY, C. et al. J Inherit Metab Dis 2008; 31: 738–44.",
                "2. WIANS, FH. Blood Tests: Normal Values. Available at: http://www.merckmanuals.com/professional/appendixes/normal-laboratory-values/blood-tests-normal-values Accessed: 18/05/16.",
                "3. INTERNATIONAL COLLABORATIVE GAUCHER GROUP (ICGG). Gaucher Registry Annual Report 2008. Available at: https://www.gauchercare.com/healthcare/registry.aspx Accessed: 12/05/16.",
                "4. ZIMRAN, A. et al. Hematology 2005; 10(2): 151–6."
            ],
            "color": "rgb(255,255,255)"
        },
        "comb1_3": {
            "title": "Hiperferritinemia",
            "txt1": [
                "La hiperferritinema se define como niveles de ferritina gt; 150 ng / mL en mujeres y gt; 200 ng / mL en hombres (rango normal: 15–200 ng / mL) <sup>1,2</sup>",
                "También es un hallazgo frecuente en la enfermedad de Gaucher, el cual ocurre en aproximadamente el 87% de los pacientes en el momento del diagnóstico <sup>3</sup>",
                "Con la hiperferritinemia, es necesario medir los niveles de saturación de transferrina en suero para excluir un diagnóstico de hemocromatosis <sup>4</sup>"
            ],
            "refs2": [
                "1. WIANS, FH. Blood Tests: Normal Values. Available at: http://www.merckmanuals.com/professional/appendixes/normal-laboratory-values/blood-tests-normal-values Accessed: 18/05/16.",
                "2. WHO. Serum ferritin concentrations for the assessment of iron status and iron deficiency in populations. Available at: http://www.who.int/vmnis/indicators/serum_ferritin.pdf Accessed: 18/05/16.",
                "3. THOMAS, AS. et al. Blood Cells Mol Dis 2013; 50(3): 212–7.",
                "4. HUGHES, D .et al. Br J Haem 2007; 138(6): 676–86."
            ],
            "color": "rgb(247,247,247)"
        },
        "banner": {
            "text": "Otras anomalías comunes de laboratorio incluyen aumento de la enzima convertidora de angiotensina (ACE) en suero y colesterol de lipoproteínas de alta densidad (HDL). <sup>1</sup>",
            "refs": [
                "THOMAS, AS. et al. Blood Cells Mol Dis 2013; 50(3): 212–7."
            ]
        }
    },
    "symptoms_skeletal": {
        "comb1_1": {
            "title": "Esquelético: dolor óseo, enfermedad ósea, evidencia radiológica de enfermedad ósea",
            "txt1": [
                "Los síntomas esqueléticos son comunes en la enfermedad de Gaucher y pueden manifestarse como enfermedad ósea, inmovilidad, dolor óseo, crisis óseas dolorosas, osteoporosis, fracturas patológicas, osteonecrosis y deformidades. <sup>1-3</sup> Los síntomas esqueléticos pueden progresar hasta el punto de causar una infección significativa. o, donde ha ocurrido daño en las vértebras, puede llevar a síntomas de médula espinal. <sup>3</sup>"
            ],
            "refs2": [
                "1. PASTORES, GM and HUGHES, DA. Gaucher Disease. 2000 Jul 27 [Updated 2015 Feb 26]. In: Pagon RA, Adam MP, Ardinger HH, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993–2015. http://www.ncbi.nlm.nih.gov/books/NBK1269/. Accessed July 2015</sup>",
                "2. NAGRAL, A. J Clin Exp Hepatol. 2014; 4(1): 37–50.",
                "3. MEHTA, A. Eur J Int Med. 2006;17:S2–S5."
            ],
            "color": "rgb(255,255,255)",
            "centerTitle": true
        }
    },
    "symptoms_neurological": {
        "comb1_1": {
            "title": "Neurológico",
            "txt1": [
                "El grado de afectación neurológica depende del tipo de enfermedad de Gaucher. Los pacientes con enfermedad de Gaucher tipo 1 (tipo no neuronopático) generalmente no tienen una enfermedad neurológica primaria. <sup>1-3</sup> Sin embargo, las complicaciones neurológicas, como la compresión de la médula espinal o de la raíces nerviosas, pueden ocurrir con la enfermedad ósea. Se ha observado neuropatía periférica, que incluye sensaciones alteradas y dolor en manos y pies, y puede ocurrir con más frecuencia de lo que se reconoció previamente. <sup>1,3-6</sup> Además, algunos casos de tipo 1 han presentado manifestaciones neurológicas como el parkinsonismo, la demencia, y neuropatía. <sup>7</sup>"
            ],
            "txt2": [
                "La enfermedad de Gaucher Tipo 2 (tipo neuronopático agudo) y el Tipo 3 (tipo neuronopático crónico) se caracteriza por la presencia de enfermedad neurológica primaria, aunque la afectación neurológica es más grave en pacientes con Tipo 2. <sup>1,2</sup> Debido a que las diferencias neurológicas no son absolutas, se reconoce cada vez más que la neuropatía representa un continuo fenotípico, que va desde los movimientos oculares rápidos más leves (sacádicos) hasta la hidropesía fetal grave. <sup>8,9</sup>"
            ],
            "refs": [
                "NAGRAL, A. Gaucher disease. J Clin Exp Hepatol. 2014; 4(1): 37–50.",
                "PASTORES, GM and HUGHES, DA. Gaucher Disease. 2000 Jul 27 [Updated 2015 Feb 26]. In: Pagon RA, Adam MP, Ardinger HH, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993–2015. http://www.ncbi.nlm.nih.gov/books/NBK1269/. [Accessed July 2015.]",
                "ESSABAR, L et al. Pan African Medical Journal. 2015; 20:18.",
                "HALPERIN, A et al. Acta Neurol Scand. 2007;115(4):275–8.",
                "CAPABLO, JL et al. J Neurol Neurosurg Psychiatry. 2008;79(2):219–22.",
                "BIEGSTRAATEN, M et al. Brain. 2010;133:2909–19.",
                "OLIVEIRA, FL et al. JIMD Rep. 2013;7:31-7.",
                "PACKMAN, W ET AL. J Inherit Metab Dis. 2006;29(1):99–105.",
                "GOKER-ALPAN, O et al. J Pediatr. 2003;143(2):273–6."
            ],
            "color": "rgb(255,255,255)",
            "centerTitle": true
        }
    },
    "diagnosis": {
        "breadcrumb": [
            {
                "link": "/",
                "name": "Inicio"
            },
            {
                "link": "/diagnosis/",
                "name": "Diagnóstico"
            }
        ],
        "banner_img": "/img/banner_diagnosis.jpg",
        "banner_msg": "Diagnóstico",
        "banner_desc": "Establecimiento y confirmación de un diagnóstico de enfermedad de Gaucher",
        "cols": {
            "color": "rgb(255,255,255)",
            "content": [
                {
                    "title": "Algoritmos de diagnóstico",
                    "desc": "Estos algoritmos están diseñados para ayudar a guiar a los profesionales de la salud a proporcionar un diagnóstico oportuno",
                    "cta": "LEER MÁS >",
                    "link": "/diagnostico/diagnostico-algoritmos"
                },
                {
                    "title": "Importancia del diagnóstico precoz",
                    "desc": "El tiempo promedio desde la primera aparición de los síntomas de la enfermedad hasta el diagnóstico final es de 2 años.",
                    "cta": "LEER MÁS >",
                    "link": "/diagnostico/importancia-diagnostico-temprano"
                }
                // {
                //     "title": "Kits Diagnósticos",
                //     "desc": "El kit de muestra en cambios de mancha (DBS) ofrece un método para recolectar sangre con el objeto de realizar una prueba de diagnóstico en pacientes con sospecha de padecer la enfermedad de Gaucher.",
                //     "cta": "LEER MÁS >",
                //     "link": "/diagnostico/kits-diagnostico"
                // }
            ]
        },
        "comb1_1": {
            "title": "Diagnóstico",
            "sub1": "La enfermedad de Gaucher puede ser difícil de diagnosticar debido a varias razones, entre ellas: <sup>1,2</sup>",
            "txt1": [
                "- La variabilidad en las características, tipo clínico y gravedad de la enfermedad.",
                "- La condición es rara, con médicos que ven casos con poca frecuencia.",
                "- Se afectan una variedad de tejidos y órganos.",
                "- Existe una amplia gama de signos y síntomas",
                "Por lo general, el diagnóstico puede tomar 48 meses,<sup>3</sup> y un diagnóstico más temprano reduciría el riesgo de que los pacientes desarrollen complicaciones o tengan procedimientos médicos innecesarios. <sup>3,4</sup>"
            ],
            "img": "/img/diagram_6_family_history.png",
            "imgtitle": "La afectación pulmonar generalmente no se observa en el momento del diagnóstico; solo el 1-2% de los pacientes con enfermedad de Gaucher tipo 1 exhiben manifestaciones pulmonares presentes en forma de enfermedad pulmonar intersticial o enfermedad vascular pulmonar.",
            "imgdesc": [
                "Una historia familiar detallada es importante para establecer un diagnóstico de la enfermedad de Gaucher."
            ],
            "refs": [
                "NAGRAL, A. J Clin Exp Hepatol. 2014;4(1): 37–50",
                "HARMANCI O., BAYRAKTAR Y. World J Gastroenterol. 2008;14:3968-73",
                "MISTRY, PK et al. Am J Hematol. 2007;82(8):697-701",
                "WEINTHAL, JA. Clin Adv Hematol Oncol. 2012;10(6), Suppl 8:10-1"
            ],
            "color": "rgb(255,255,255)"
        },
        "comb1_2": {
            "title": "Estableciendo un diagnóstico",
            "txt1": [
                "Tras un examen físico, la enfermedad de Gaucher se puede detectar y confirmar mediante una evaluación clínica, bioquímica y genética. Los recuentos sanguíneos, las pruebas de función hepática, las imágenes médicas y las mediciones de actividad de la glucocerebrosidasa en leucocitos sanguíneos y / o fibroblastos de la piel se determinan con frecuencia. <sup>1-3</sup>"
            ],
            "img": "/img/diagram_7_table_signs_symptoms-736x321.svg",
            "imgdesc": [
                "Signos y síntomas típicos previos al diagnóstico."
            ],
            "refs": [
                "NAGRAL, A. J Clin Exp Hepatol. 2014;4(1): 37–50",
                "LUTSKY KF, TEJWANI NC. Bulletin of the NYU Hospital for Joint Diseases. 2007;65:37-42",
                "GRABOWSKY GA Lancet 2008; 372: 1263-1271"
            ],
            "color": "rgb(247,247,247)"
        },
        "custom_comb": {
            "title": "Confirmando un diagnóstico de enfermedad de Gaucher",
            "txt1": [
                "El estándar de oro para el diagnóstico de la enfermedad de Gaucher es demostrar una baja actividad de la β-glucocerebrosidasa en los leucocitos de sangre periférica. <sup>1,2</sup> Los fibroblastos de la piel también se pueden usar.1 Por lo general, los pacientes con enfermedad de Gaucher tipo 1 tienen un 10-30% de la actividad normal de la enzima. <sup>2</sup>",
                "Además, existe una correlación directa entre los niveles de actividad residual de la β-glucocerebrosidasa en los fibroblastos de la piel y el tipo de enfermedad de Gaucher. <sup>3</sup>",
                "La actividad enzimática residual más alta se produce en los fibroblastos de pacientes con enfermedad de Gaucher tipo 1 que en los fibroblastos de pacientes con tipos 2 o 3. <sup>3</sup> Las pruebas genéticas (mutación de ADN) también confirman el diagnóstico y son confiables para determinar el estado de portador de familiares cercanos de pacientes con un diagnóstico confirmado de enfermedad de Gaucher tipo 1. Para los portadores, las pruebas de enzimas no se consideran confiables, por lo que las pruebas de portadores dependen de la identificación de la mutación específica en el paciente probando. <sup>4</sup>"
            ],
            "refs": [
                "NAGRAL, A. J Clin Exp Hepatol. 2014;4(1): 37–50.",
                "HARMANCI, O, and Bayraktar, Y. J Gastroenterol. 2008;14:3968-73.",
                "MEIVAR-LEVY, I et al. Biochem J. 1994;303:377-82.",
                "KOPRIVICA, V et al. Am J Hum Genet. 2000;66:1777–86."
            ],
            "color": "rgb(255,255,255)"
        },
        "vid1": {
            "vid": "https://www.youtube.com/embed/ydBx1ZqADzo",
            "viddesc": "Diagnóstico de pacientes con enfermedad de Gaucher: ¿Dónde estamos hoy? - Profesor Ari Zimran",
            "color": "rgb(247,247,247)"
        }
    },
    "diagnostic_alg": {
        "comb1_1": {
            "title": "Algoritmos de diagnóstico",
            "sub1": "Hay tres algoritmos de detección que se aplican a poblaciones diferentes y específicas. Se pueden usar para ayudar a los profesionales de la salud (HCP) a diagnosticar la enfermedad de Gaucher en los pacientes después de los exámenes y las pruebas hematológicas. <sup>1,2</sup>",
            "txt2": [
                "Hay dos algoritmos para adultos (de origen judío ashkenazi y no judío ashkenazi) y un algoritmo pediátrico. Los tres algoritmos están diseñados para guiar a los profesionales de la salud en el diagnóstico oportuno de la enfermedad de Gaucher."
            ],
            "links2": [
                "Algoritmo de diagnóstico para individuos de origen judío Ashkenazi;;;#ashkenazi",
                "Algoritmo de diagnóstico para individuos de origen judío no ashkenazi;;;#no-ashkenazi",
                "Algoritmo de diagnóstico pediátrico;;;#pediatrico"
            ],
            "refs": [
                "MISTRY, P. et al. Am J Hematol 2011; 86(1): 110–5.",
                "DI ROCCO, M. et al. Pediatr Blood Cancer 2014; 61(11): 1905–9."
            ],
            "color": "rgb(255,255,255)"
        },
        "comb2_1": {
            "title": "Algoritmo de diagnóstico para individuos de origen judío no ashkenazí",
            "content": [
                {
                    "content": "La esplenomegalia es un síntoma clave de la enfermedad de Gaucher y está presente en la gran mayoría de los pacientes de Gaucher y constituye el punto de partida de este algoritmo. La enfermedad de Gaucher se debe considerar en cualquier individuo judío Ashkenazi que presente esplenomegalia de cualquier severidad."
                },
                {
                    "content": "Sin embargo, si no hay esplenomegalia, se puede considerar la enfermedad de Gaucher en presencia de trombocitopenia, tendencia al sangrado, hiperferritinemia estable inexplicable con saturación de transferrina normal o aumento de marcadores inflamatorios. Tenga en cuenta que un diagnóstico confirmado de la enfermedad de Gaucher requiere un análisis enzimático realizado con leucocitos o fibroblastos."
                }
            ],
            "img": "/img/diagram_4_jewish_algorithm.png",
            "refs": [
                "MISTRY, P. et al. Am J Hematol 2011; 86(1): 110–5."
            ],
            "color": "rgb(247,247,247)"
        },
        "comb2_2": {
            "title": "Algoritmo de diagnóstico para individuos de origen ashkenazi.",
            "content": [
                {
                    "content": "La esplenomegalia es un síntoma clave de la enfermedad de Gaucher y está presente en la gran mayoría de los pacientes de Gaucher,  constituyendo el punto de partida de este algoritmo. Sin embargo, en la población general, la malignidad es la causa más probable de esplenomegalia. Por lo tanto, es razonable realizar una biopsia de médula ósea en las investigaciones iniciales para determinar la presencia de células de Gaucher en el aspirado de médula ósea."
                },
                {
                    "content": "Cabe señalar que la enfermedad de Gaucher y la malignidad no son mutuamente excluyentes, y también se han observado células pseudo-Gaucher en condiciones malignas en ausencia de la enfermedad de Gaucher. Por lo tanto, es importante confirmar un diagnóstico de enfermedad de Gaucher con un ensayo enzimático realizado con leucocitos o fibroblastos."
                }
            ],
            "img": "/img/diagram_5_algorithm.png",
            "refs": [
                "MISTRY, P. et al. Am J Hematol 2011; 86(1): 110–5."
            ],
            "color": "rgb(255,255,255)"
        },
        "comb2_3": {
            "title": "Algoritmo de diagnóstico para el grupo de edad pediátrico.",
            "content": [
                {
                    "content": "De manera similar a los algoritmos para adultos, la presencia de esplenomegalia se usa como punto de partida, ya que ocurre en hasta el 95% de los pacientes pediátricos 2. Debe notarse que los síntomas en adultos como cálculos biliares, nódulos esplénicos, trombocitopenia asociada al embarazo, la hemorragia post-parto, o la gamapatía monoclonal de importancia indeterminada no debe considerarse al evaluar a los pacientes pediátricos."
                },
                {
                    "content": "Sin embargo, considere la evidencia radiológica de enfermedad ósea, retraso del crecimiento, anomalías oculomotoras y ferritina o niveles de TRAP, ya que pueden acortar el tiempo necesario para diagnosticar la enfermedad de Gaucher en niños. Tenga en cuenta que un diagnóstico confirmado de la enfermedad de Gaucher requiere un análisis enzimático realizado con leucocitos o fibroblastos."
                }
            ],
            "img": "/img/diagram_3_pediatric_algorithm-1024x560.png",
            "refs": [
                "DI ROCCO, M. et al. Pediatr Blood Cancer 2014; 61(11): 1905–9."
            ],
            "color": "rgb(247,247,247)"
        }
    },
    "importance_early_diag": {
        "custom_comb1": {
            "title": "Importancia del diagnóstico precoz",
            "sub1": "El retraso en el diagnóstico es común en la enfermedad de Gaucher. En una encuesta de pacientes con enfermedad de Gaucher tipo 1, el tiempo promedio desde la primera aparición de los síntomas de la enfermedad hasta el diagnóstico final fue de 48 meses. <sup>1</sup>",
            "txt1": "El linfoma, la enfermedad hepática, la fibromialgia, los dolores del crecimiento y la osteoporosis se encuentran entre los diagnósticos originales realizados en estos pacientes. Además, una encuesta global de especialistas en hematología y oncología que manejaban pacientes encontró que solo el 20% consideraba un diagnóstico de enfermedad de Gaucher cuando se presentaban los signos y síntomas clásicos (dolor óseo, hepatoesplenomegalia y citopenia). <sup>1</sup> Esto resalta la necesidad de aumentar la concientización y recordar con frecuencia a los médicos que la enfermedad de Gaucher es un diagnóstico posible para los pacientes con estos signos y síntomas.",
            "sub2": "El tiempo promedio desde la primera aparición de los síntomas de la enfermedad hasta el diagnóstico final es de 2 años, sin embargo, casi una quinta parte de los pacientes experimentan un retraso de ≥5 años <sup>2</sup>",
            "refs": [
                "MISTRY, PK et al. Am J Hematol. 2007;82(8):697-701.",
                "THOMAS, AS. et al. Blood Cells Mol Dis 2013; 50(3): 212–7."
            ],
            "color": "rgb(255,255,255)"

        },
        "custom_comb2": {
            "title": "Consecuencias del retraso en el diagnóstico",
            "sub": "La presentación de la enfermedad de Gaucher tipo 1 puede ocurrir en cualquier momento desde la primera infancia hasta la vejez.<sup>1</sup> En consecuencia, más de la mitad de los pacientes con enfermedad de Gaucher tipo 1 se diagnostican antes de los 20,2 años de edad, con una edad más temprana de inicio la cual predice un curso más agresivo de la enfermedad.<sup>1</sup>",
            "txt": [
                "Además, los retrasos diagnósticos prolongados pueden dar lugar a problemas clínicos severos e irreversibles, incluido daño irreversible a los órganos; fracturas; septicemia; complicaciones de sangrado que amenazan la vida; y retraso del crecimiento en niños. <sup>3</sup>",
                "Sin embargo, un diagnóstico más temprano y más preciso de la enfermedad de Gaucher podría ayudar a reducir el impacto de la progresión de la enfermedad a lo largo del tiempo al permitir que los pacientes reciban el tratamiento adecuado y la atención especializada más pronto, <sup>4</sup> y evitar procedimientos médicos inapropiados o innecesarios, como la esplenectomía y las biopsias o tratamientos. <sup>3,5,6</sup>"
            ],
            "imgs": [
                {
                    "title": "La aparición temprana de la enfermedad predice un curso más agresivo de la enfermedad. <sup>1,2</sup>",
                    "src": "/img/diagram_14a.png"
                },
                {
                    "title": "Consecuencias del Diagnóstico Temprano<sup>3</sup>",
                    "src": "/img/diagram_15a.png"
                },
                {
                    "title": "Diagnóstico errado puede resultar en procedimientos innecesarios<sup>6</sup>",
                    "src": "/img/diagram_16a.png"
                }
            ],
            "refs": [
                "BURROW, TA. et al. Pediatric Health Med Ther 2011; 2: 59–73; 2.",
                "GRABOWSKI, GA. Lancet 2008; 372: 1263–71.",
                "MISTRY, P. et al. Am J Hematol 2007; 82(8): 697–701.",
                "JOHNSON, BA. Curr Protoc Hum Genet 2014; 82: 17.15.1–6.",
                "WEINTHAL, JA. Clin Adv Hematol Oncol. 2012;10(6), Suppl 8:10‒1.",
                "MISTRY, P. et al. Am J Hematol 2011; 86(1): 110–5."
            ],
            "color": "rgb(255,255,255)"
        }
    },
    "dbskits": {
        "custom_comb1": {
            "title": "Kits Diagnósticos",
            "text": "El kit de muestra en cambios de mancha (DBS) ofrece un método para recolectar sangre con el objeto de realizar una prueba de diagnóstico en pacientes con sospecha de padecer la enfermedad de Gaucher. Es importante que el diagnóstico clínico se confirme mediante una combinación de pruebas de biomarcadores genéticos y otros específicos de la enfermedad, y ayuda a garantizar que los pacientes reciban apoyo y tratamiento especializado oportuno.",
            "sub": "El kit DBS se debe utilizar después de la identificación inicial de los síntomas que pueden sugerir la enfermedad de Gaucher.",
            "color": "rgb(255,255,255)"
        },
        "custom_comb2": {
            "content": {
                "title": "Contenidos Kit DBS",
                "cols1": {
                    "img": "/img/diagram21d-1-414x395.png",
                    "text": "Un formulario de solicitud de prueba DBS adjunto a una tarjeta de filtro para la recolección de manchas de sangre."
                },
                "cols2": {
                    "img": "/img/diagram22a-512x414.png",
                    "text": "El formulario de consentimiento informado del paciente."
                },
                "cols3": {
                    "img": "/img/diagram22b-1-414x371.png",
                    "text": "Un sobre 'devolver al laboratorio' con una dirección previa."
                }
                
            },
            "content2": {
                "title": "La sangre se puede extraer mediante punción venosa o pinchando un dedo",
                "cols1": {
                    "img": "/img/diagram21a-400x400.png",
                    "text": "Desinfectar el brazo o el dedo del paciente."
                },
                "cols2": {
                    "img": "/img/diagram21b-400x400.png",
                    "text": "Realice la punción venosa siguiendo los procedimientos de EDTA desde el brazo o, pinche el dedo seleccionado con una lanceta estéril."
                }
            },
            "content3": {
                "title": "La sangre se recoge en tarjetas DBS, se procesa y se envía para su análisis",
                "cols1": {
                    "img": "/img/diagram21c-400x400.png",
                    "text": "Pipetee 50 μl de la muestra requerida o aproximadamente 3 gotas del dedo en el círculo sin tocar la superficie de la tarjeta del filtro. El círculo entero debe estar saturado uniformemente. Cuando se aplica correctamente, la mancha de sangre será visible en la parte posterior de la tarjeta."
                },
                "cols2": {
                    
                    "img": "/img/diagram21d-1-414x395.png",
                    "text": "Continuar el procedimiento hasta que las 10 manchas de sangre requeridas estén completamente saturadas. La tarjeta debe secarse al aire durante al menos 3 horas. Se recomienda que las tarjetas de filtro completadas se envíen entre 1 y 2 semanas."
                    
                }
            },
            "desc": "Las muestras de DBS de pacientes con enfermedad de Gaucher se analizan para determinar la actividad de la β-glucocerebrosidasa (GBA) seguida de la secuenciación del gen GBA.",
            "color": "rgb(255,255,255)"
        },
        "custom_comb3": {
            "color": "rgb(247,247,247)",
            "title": "Consultas sobre el kit de DBS ",
            "text": "Para consultas relacionadas con el kit de DBS, comuníquese con el equipo de Takeda Global Medical Affairs. Para solicitar kits de DBS, comuníquese con su representante local de Takeda. Para consultas relacionadas con los resultados de las pruebas, comuníquese con Centogene AG y asegúrese de proporcionar la información demográfica de su paciente.",
            "content": [
                {
                    "sub": "Takeda Global Medical Affairs and Quality:",
                    "mail": "DBSkitenquiries@Takeda.com"
                },
                {
                    "sub": "Consultas sobre Pruebas / Resultados:",
                    "text": "Centogene AG Schillingallee 68 18057 Rostock Alemania",
                    "mail": "testing@centogene.com"
                },
                {
                    "sub": "Pruebas de laboratorio y análisis:",
                    "text": "DBSkitenquiries@Takeda.com"
                }
            ]
        }
    },
    "management": {
        "banner_img": "/img/banner_management.jpg",
        "banner_msg": "Manejo",
        "banner_desc": "Terapia de reemplazo enzimático, terapia de reducción de sustrato y tratamiento sintomático",
        "comb1_1": {
            "title": "Manejo",
            "txt1": [
                "En vista de la naturaleza genética de la enfermedad de Gaucher, actualmente no hay cura disponible. Por lo tanto, el objetivo final del manejo de la enfermedad de Gaucher es reducir la acumulación del sustrato tóxico glucocerebrosido y otros glicolípidos para prevenir la enfermedad progresiva con complicaciones serias. <sup>1</sup> Esto se puede lograr mediante la terapia de reemplazo enzimático (TRE) o la terapia de reducción de sustrato (TRS).",
                "Como la enfermedad de Gaucher es variable en su presentación y manifestaciones, los médicos pueden individualizar los objetivos de tratamiento para cada paciente teniendo en cuenta todos los signos y síntomas posibles, e identificar los objetivos de tratamiento para cada una de estas afecciones. <sup>1-3</sup>"
            ],
            "sub2": "Un panel de expertos globales acordó un consenso para ocho objetivos terapéuticos de tratamiento en la enfermedad de Gaucher, que incluyen: <sup>3</sup>",
            "txt2": [
                "1. Hepatomegalia (reducir el volumen del hígado)",
                "2. Esplenomegalia (reducir el volumen del bazo)",
                "3. Patología esquelética (tratamiento del dolor óseo, crisis óseas)",
                "4. Anemia (mejorar el nivel de hemoglobina)",
                "5. Trombocitopenia (aumento del recuento de plaquetas)",
                "6. Crecimiento (normalizar población pediátrica)",
                "7. Afectación pulmonar (mejora la función pulmonar)",
                "8. Salud funcional y bienestar (mejoría o restauración de la función física)."
            ],
            "refs": [
                "WEINREB, NJ. et al. Am J Hematol 2008; 83(12): 896–900.",
                "GRABOWSKI, GA. Lancet 2008; 372: 1263–71",
                "MEHTA A. Eur J Int Med. 2006; 17:S2–S5."
            ],
            "color": "rgb(255,255,255)"
        },
        "comb2_1": {
            "content": [
                {
                    "title": "Terapia de reemplazo enzimático",
                    "content": "La TRE para la enfermedad de Gaucher está diseñada para reemplazar una enzima lisosomal defectuosa o faltante específica (β-glucocerebrosidasa), como se muestra en la figura a continuación. <sup>1</sup> La enzima de reemplazo ayuda a descomponer el sustrato de glicosfingolípidos, lo que evita la acumulación del material de almacenamiento y el daño tisular posterior. <sup>1,2</sup>"
                }
            ],
            "img": "/img/diagram_20a_treatment-1.png",
            "refs": [
                "KOLTER, T and SANDHOFF, K. Biochimica et Biophysica Acta. 2006;1758:2057–79.1.",
                "LUTSKY, KF and TEJWANI, NC. Bulletin of the NYU Hospital for Joint Diseases. 2007;65:37–42."
            ],
            "color": "rgb(247,247,247)",
            "center": true
        },
        "comb2_2": {
            "content": [
                {
                    "title": "Terapia de reducción de sustrato",
                    "content": "La TRS es un método utilizado para tratar las LSD mediante la inhibición de la síntesis del sustrato y la posterior entrada en los lisosomas, como se muestra en la siguiente figura.<sup>1</sup> En la enfermedad de Gaucher, la TRS reduce la tasa de producción de sustrato de glicoesfingolípidos y, por lo tanto, la acumulación lisosomal."
                }
            ],
            "img": "/img/diagram_20b_treatment.png",
            "refs": [
                "KOLTER, T and SANDHOFF, K. Biochimica et Biophysica Acta. 2006;1758:2057–79."
            ],
            "color": "rgb(255,255,255)",
            "center": true
        },
        "comb1_2": {
            "title": "Tratamiento sintomático",
            "sub1": "La atención de apoyo para aliviar los síntomas de la enfermedad de Gaucher es una parte integral del manejo de la enfermedad y debe ser brindada por un equipo multidisciplinario con experiencia en la enfermedad de Gaucher. <sup>1</sup>",
            "txt1": [
                "El tratamiento de las manifestaciones óseas se dirige principalmente a la prevención de complicaciones irreversibles2. Por ejemplo, los pacientes con osteopenia u osteoporosis pueden tratarse con bisfosfonatos y calcio / vitamina D. <sup>1,3,4</sup>",
                "La cirugía ortopédica puede ser necesaria en casos de fracturas patológicas o necrosis avascular. Los dolores óseos o las crisis óseas se pueden tratar con reposo en cama, analgésicos y prednisolona oral. <sup>3,4</sup>",
                "La anemia puede requerir transfusiones de sangre después de confirmar que no hay deficiencia de hierro o vitamina B12. <sup>1,3</sup>",
                "La atención de apoyo para aliviar los síntomas de la enfermedad de Gaucher es una parte integral del manejo de la enfermedad y debe ser brindada por un equipo multidisciplinario con experiencia en la enfermedad de Gaucher. <sup>1</sup>"
            ],
            "txt2": [
                "Una tendencia a la hemorragia indica anomalías en el recuento y función de las plaquetas, y factores de coagulación, que pueden requerir transfusiones de plaquetas y otras preparaciones apropiadas antes de procedimientos quirúrgicos o embarazo. <sup>1,3,4</sup>",
                "Una vez popular, la esplenectomía ahora se realiza solo en casos de trombocitopenia grave u organomegalia sintomática que no responden a la TRE. <sup>1,3,4</sup>",
                "Los trasplantes de hígado rara vez son necesarios en la actualidad, pero pueden considerarse en casos de fibrosis hepática progresiva, hipertensión portal y cirrosis. <sup>3,4</sup>",
                "La enfermedad neuronopática es mejor tratada por un neurólogo y usando medicamentos específicos como los indicados para las convulsiones y los síntomas del parkinsonismo. Las enfermedades malignas deben remitirse al oncólogo apropiado para su terapia. <sup>1,3</sup>"
            ],
            "refs": [
                " PASTORES, GM and HUGHES, DA. Gaucher Disease. 2000 Jul 27 [Updated 2015 Feb 26]. In: Pagon RA, Adam MP, Ardinger HH, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2015. Available from: http://www.ncbi.nlm.nih.gov/books/NBK1269/.",
                "Genzyme Europe B.V. Cerdelga® Summary of product characteristics, 2015. Available at: http://ec.europa.eu/health/documents/community-register/2015/20150119130463/anx_130463_en.pdf. Accessed September 2015.",
                "NAGRAL, A. J Clin Exp Hepatol. 2014;4(1):37–50.",
                "JMOUDIAK, M and FUTERMAN, AH. Br J Haematol. 2005;129:178–88."
            ],
            "color": "rgb(247,247,247)"
        },
        "vid1": {
            "vid": "https://www.youtube.com/embed/BaACfZbNWJU",
            "viddesc": "Evaluación de la calidad de vida en la práctica clínica: una perspectiva del médico - Dr. Gregory Pastores",
            "color": "rgb(255,255,255)"
        },
        "cols": {
            "color": "rgb(255,255,255)",
            "content": [
                {
                    "title": "Terapia de reemplazo enzimático",
                    "class": "managment",
                    // "desc": "Síntomas viscerales asociados a la enfermedad de Gaucher",
                    "cta": "LEER MÁS >",
                    "link": "#terapia_enzimatica"
                },
                {
                    "title": "Terapia de reducción de sustrato",
                    "class": "managment",
                    // "desc": "Síntomas hematológicos asociados a la enfermedad de Gaucher",
                    "cta": "LEER MÁS >",
                    "link": "#sustrato_terapia"
                },
                {
                    "title": "Tratamiento sintomático",
                    "class": "managment",
                    // "desc": "Síntomas esqueléticos asociados con la enfermedad de Gaucher",
                    "cta": "LEER MÁS >",
                    "link": "#tratamiento_sintomatico"
                }
            ]
        },
    },
    "referral_info": {
        "banner_img": "/img/banner_referral_info.jpg",
        "banner_msg": "Asesoría Genética",
        "banner_info": "Análisis genealógicos y consejeros genéticos",
        "comb1_1": {
            "title": "Información de referencia",
            "txt1": [
                "Los pacientes pueden ser referidos a su centro de especialistas más cercano.",
                "La mayoría de los centros especializados apoyan la comunicación familiar, la educación y el diagnóstico con reuniones familiares periódicas y clínicas abiertas, recolección de muestras y distribución de kits de pruebas.",
                "Su representante local de Takeda ofrece ayuda educativa para profesionales de la salud y pacientes."
            ],
            "sub2": "Éstos incluyen:",
            "txt2": [
                "Un folleto resumen para profesionales de la salud.",
                "Una herramienta electrónica para profesionales de la salud.",
                "Un curso de formación para no genetistas."
            ],
            "color": "rgb(255,255,255)"
        },
        "custom_comb": {
            "title": "Análisis Familiar",
            "sub1": "Cuando existe la sospecha de que un paciente puede verse afectado por la enfermedad de Gaucher, se debe realizar un Análisis genealógico para informar el diagnóstico e identificar a los familiares del paciente en riesgo del paciente.",
            "txt1": "Un Pedigree es un diagrama multigeneracional que hace referencia a las relaciones genéticas, la información demográfica y el historial de salud de una familia. Los pedigrees usan símbolos estándar y terminología específica, lo que permite la representación de grandes cantidades de información para visualizar los patrones de herencia y la aparición de las manifestaciones de la enfermedad. <sup>1</sup>",
            "refs1": [
                "BENNETT RL, et al. J Genet Counsel. 2008;17:424–433"
            ],
            "img": "/img/diagram_13_family_tree-2-736x217.png",
            "imgdesc": "Ejemplo de análisis de pedigree",
            "txt2": "El análisis genealógico por lo general implica la recopilación de los antecedentes familiares del paciente hasta tres generaciones. Existe una nomenclatura estándar de Pedigree humano <sup>1</sup> y el análisis se puede realizar a mano o utilizando el software de dibujo Pedigree.",
            "txt3": "Una vez completado, el análisis de pedigree representa las relaciones genéticas familiares,  información demográfica y el historial de salud familiar. El análisis permite la visualización de patrones de herencia, la identificación de parientes en riesgo y la identificación de portadores potenciales.",
            "refs2": [
                "BENNETT RL, et al. J Genet Counsel. 2008;17:424–433"
            ],
            "color": "rgb(247,247,247)"
        },
        "custom_comb2": {
            "title": "Herencia",
            "txt1": "La enfermedad de Gaucher se debe a defectos en la función de la β-glucocerebrosidasa, que está codificada por el gen GBA1.1 Se han identificado más de 350 mutaciones diferentes en el gen GBA1, incluidas sustituciones de un solo nucleótido (mutaciones puntuales), sustituciones, inserciones, deleciones y reordenamientos. <sup>2,3</sup> A pesar de la gran cantidad de mutaciones genéticas identificadas, la mayoría de los casos de enfermedad de Gaucher son el resultado de varias mutaciones comunes en el gen GBA1. <sup>3,4</sup>",
            "txt2": "La enfermedad de Gaucher es un trastorno autosómico recesivo, ya que el gen GBA1 mutado se encuentra en el cromosoma 1 (un cromosoma autosómico, no X o Y) y solo se expresa en homocigotos (donde ambos alelos codifican la glucocerebrosidasa defectuosa). <sup>4</sup> Como resultado, los hombres y las mujeres se ven igualmente afectados por la enfermedad de Gaucher debido a su naturaleza autosómica recesiva.",
            "txt3": [
                "Si ambos padres son portadores de la enfermedad de Gaucher, existe una probabilidad de 1 en 4 (25%) de que sus hijos heredarán dos copias del gen mutado de la enfermedad de Gaucher (a) y desarrollarán la enfermedad de Gaucher.",
                "Si solo un padre es portador, no hay posibilidad de que el niño tenga la enfermedad de Gaucher. Sin embargo, existe una probabilidad de 1 en 2 (50%) de que el niño sea portador."
            ],
            "img": "/img/diagram_10_parent-414x486.png",
            "refs": [
                "Genetics Home Reference http://ghr.nlm.nih.gov/condition/gaucher-disease. Accessed: 31 July 2015.",
                "GRABOWSKI GA. Hematology Am Soc Hematol Educ Program. 2012:13–8.",
                "NAGRAL A. J Clin Exp Hepatol. 2014; 4:37–50.",
                "Genetics Home Reference http://ghr.nlm.nih.gov/gene/GBA. Accessed: 31 July 2015."
            ]
        },
        "comb1_2": {
            "title": "Consejeros genéticos",
            "txt1": [
                "Después de un diagnóstico de la enfermedad de Gaucher, se debe ofrecer asesoramiento genético a cada paciente para ayudarles a evaluar la situación y decidir si / cómo comunicarse con su familia. La negación y la falta de comunicación con la familia se observan con frecuencia, especialmente en casos de manifestaciones graves de la enfermedad. Las tensiones familiares suelen estar expuestas tras un diagnóstico."
            ],
            "txt2": [
                "También se debe ofrecer asesoramiento genético a los miembros de la familia. Esto implica un intento por parte de personas adecuadamente capacitadas para ayudar a la persona o la familia a:",
                "Comprender los datos médicos (incluido el diagnóstico, el curso probable del trastorno y las opciones de manejo disponibles",
                "Apreciar la forma en que la herencia contribuye al trastorno y el riesgo de recurrencia en parientes específicos",
                "Comprender las alternativas para afrontar el riesgo de recurrencia.",
                "- Elegir el curso de acción apropiado en vista de su riesgo, sus metas familiares y su estándar ético y religioso",
                "- Hacer el mejor ajuste posible al trastorno en un miembro de la familia afectado <sup>1</sup>"
            ],
            "refs": [
                "Ad Hoc Committee on Genetic Counseling. Am J Hum Genet. 1975; 27:240–242."
            ],
            "color": "rgb(255,255,255)"
        },
        "vid1": {
            "vid": "https://www.youtube.com/embed/SZ7or63IV8M",
            "viddesc": "Viaje hacia el diagnóstico - Historias de pacientes",
            "color": "rgb(247,247,247)"
        }
    },
    "epidemiology": {
        "banner_img": "/img/banner_epidemiology.jpg",
        "banner_msg": "Epidemiología",
        "banner_desc": "Herencia y autosómica recesiva",
        "comb1_1": {
            "title": "Epidemiología",
            "txt1": [
                "La prevalencia general de la enfermedad de Gaucher es baja, 1 se produce con una frecuencia de aproximadamente 1 en 50,000 a 1 en 100,000 nacidos vivos.<sup>2</sup> Más del 90% de los pacientes con enfermedad de Gaucher son de Tipo 1.<sup>2</sup> Sin embargo, el Tipo 1 todavía es raro, y solo ocurre en alrededor de 1 en 30,000 a 1 en 40,000 personas en la población general, y debido a la consanguinidad.<sup>1</sup> Los estudios epidemiológicos han demostrado que la incidencia del trastorno es mucho mayor entre los judíos Ashkenazi (aquellos con origen en Europa del Este), con una incidencia de 1 en 855 nacimientos judíos asquenazíes y una frecuencia estimada de portador de 1 en 18 personas<sup>3</sup>. En contraste, los tipos 2 y 3 tienen una incidencia muy rara, y ocurren en menos de 1 en 100,000 de la población general. <sup>1</sup>"
            ],
            "refs2": [
                "1. MEHTA, A. Eur J Int Med. 2006;17:S2–S5.",
                "2. NICE: National Institute for Health and Care Excellence Final scope for the highly specialised technology evaluation of eliglustat for treating type 1 Gaucher disease Issue Date: September 2014 Appendix B",
                "3. PASTORES, GM and HUGHES, DA. Gaucher Disease. 2000 Jul 27 [Updated 2015 Feb 26]. In: Pagon RA, Adam MP, Ardinger HH, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993–2015. http://www.ncbi.nlm.nih.gov/books/NBK1269/. [Accessed July 2015]."
            ],
            "color": "rgb(255,255,255)"
        },
        "custom_comb": {
            "title": "Herencia",
            "txt1": "La enfermedad de Gaucher se debe a defectos en la función de la β-glucocerebrosidasa, que está codificada por el gen GBA1.1 Se han identificado más de 350 mutaciones diferentes en el gen GBA1, incluidas sustituciones de un solo nucleótido (mutaciones puntuales), sustituciones, inserciones, deleciones y reordenamientos. <sup>2,3</sup> A pesar de la gran cantidad de mutaciones genéticas identificadas, la mayoría de los casos de enfermedad de Gaucher son el resultado de varias mutaciones comunes en el gen GBA1. <sup>3,4</sup>",
            "txt2": "La enfermedad de Gaucher es un trastorno autosómico recesivo, ya que el gen GBA1 mutado se encuentra en el cromosoma 1 (un cromosoma autosómico, no X o Y) y solo se expresa en homocigotos (donde ambos alelos codifican la glucocerebrosidasa defectuosa). <sup>4</sup> Como resultado, los hombres y las mujeres se ven igualmente afectados por la enfermedad de Gaucher debido a su naturaleza autosómica recesiva.",
            "txt3": [
                "Si ambos padres son portadores de la enfermedad de Gaucher, existe una probabilidad de 1 en 4 (25%) de que sus hijos heredarán dos copias del gen mutado de la enfermedad de Gaucher (a) y desarrollarán la enfermedad de Gaucher.",
                "Si solo un padre es portador, no hay posibilidad de que el niño tenga la enfermedad de Gaucher. Sin embargo, existe una probabilidad de 1 en 2 (50%) de que el niño sea portador."
            ],
            "img": "/img/diagram_10_parent-414x486.png",
            "refs": [
                "Genetics Home Reference http://ghr.nlm.nih.gov/condition/gaucher-disease. Accessed: 31 July 2015.",
                "GRABOWSKI GA. Hematology Am Soc Hematol Educ Program. 2012:13–8.",
                "NAGRAL A. J Clin Exp Hepatol. 2014; 4:37–50.",
                "Genetics Home Reference http://ghr.nlm.nih.gov/gene/GBA. Accessed: 31 July 2015."
            ]
        },
        "comb1_2": {
            "title": "Autosómico Recesivo",
            "txt1": [
                "El patrón de herencia de un trastorno de un solo gen depende de si el gen afectado está en un cromosoma sexual o un cromosoma autosómico (cualquier cromosoma no sexual). <sup>1,2</sup> Los trastornos autosómicos se relacionan con los genes en los cromosomas autosómicos, es decir, no se transmiten por el cromosoma X o Y. <sup>1,2</sup> El patrón de herencia también depende de si el gen afectado se expresa como un rasgo dominante o recesivo. Un rasgo recesivo se expresa solo en homocigotos para ese gen (es decir, lleva 2 copias del gen mutado)."
            ],
            "txt2": [
                "Por lo tanto, los trastornos autosómicos recesivos se relacionan con los genes en un cromosoma autosómico que solo se expresan en homocigotos. Para la enfermedad de Gaucher, esto significa que un niño debe heredar el gen GBA1 mutado recesivo de ambos padres para desarrollar la enfermedad. <sup>1</sup> Una persona con enfermedad de Gaucher, por lo tanto, tiene dos copias del gen mutado, mientras que una persona con un gen dominante y uno recesivo se define como un portador."
            ],
            "refs": [
                "EVANS, J. Crash Course: Cell Biology and Genetics. Mosby Elsevier, Third Edition, 2008.",
                "OPENSTAX COLLEGE, Rice University. Biology (2013). ISBN-10 1938168399; ISBN-13; 978-1-938168-39-0; Revision C-1-000-DW. Available at: http://openstaxcollege.org Accessed May 2015."
            ],
            "color": "rgb(255,255,255)"
        },
        "vid1": {
            "vid": "https://www.youtube.com/embed/CyrKu3JO8hQ",
            "viddesc": "La vida con la enfermedad de Gaucher - La historia de Elaine y Brian",
            "color": "rgb(247,247,247)"
        }
    },
    "member": {
        "breadcrumb": [
            {
                "link": "/",
                "name": "Inicio"
            }
        ],
        "content": {
            "title":"Comuniquese con miembro del equipo medico"
        },
        "form": {
            "title": "Por favor, complete sus datos a continuación",
            "subtitle": "Los campos marcados con * son campos obligatorios",
            "fields": [
                {
                    "name": "Pregunta",
                    "validate": "Por favor, ingrese su pregunta"
                },
                {
                    "name": "Nombre",
                    "validate": "Por favor, ingrese su nombre"
                },
                {
                    "name": "Apellidos",
                    "validate": "Por favor, ingrese su apellido"
                },
                {
                    "name": "Tipo de documento",
                    "validate": "Por favor, seleccione una opción"
                },
                {
                    "name": "Número de documento",
                    "validate": "Por favor, ingrese su número de documento"
                },
                {
                    "name": "Registro médico",
                    "validate": "Por favor, ingrese su registro médico"
                },
                {
                    "name": "Teléfono",
                    "validate": "Por favor, ingrese su teléfono"
                },
                {
                    "name": "Correo electrónico",
                    "validate": "Por favor, ingrese su correo electrónico",
                    "invalid": "Por favor, ingrese un correo electrónico válido"
                },
                {
                    "name": "Especialidad",
                    "validate": "Por favor, ingrese su especialidad"
                },
                {
                    "name": "Cualquier uso de este sitio constituye su acuerdo con los ",
                    "validate": "Por favor, acepte los Términos y Condiciones para continuar"
                },
                {
                    "name": "Su consentimiento: Takeda desea obtener su consentimiento para recopilar y procesar sus datos personales como se describe en este ",
                    "validate": "Por favor, dé su consentimiento para continuar"
                },
                {
                    "name": "Recaptcha",
                    "validate": "Por favor, tilde el Recaptcha para continuar"
                }
            ],
            "success": "Agradecemos su contacto.<br>Un miembro del equipo se comunicará con usted a la brevedad.",
            "mailError": "Hubo un problema al enviar el formulario. Vuelve a intentarlo más tarde.",
            "recaptchaError": "Por favor, revise el formulario de captcha."
        }
    },
    "terms": {
        "breadcrumb": [
            {
                "link": "/",
                "name": "Inicio"
            }
        ],
        "current_breadcrumb": "Términos y condiciones",
        "title": "TÉRMINOS Y CONDICIONES",
        "date": "Fecha: 26 de Abril de 2018",
        "content": [
            {
                "id": "t1",
                "title": "1. APLICABILIDAD",
                "txt": [
                    "El Portal web Portal Enfermedad de Gaucher (en adelante el “Portal”) de propiedad de Baxalta Colombia S.A.S. y administrado por Baxalta Colombia S.A.S. (en adelante “Takeda”, la “Compañía”, “nuestro”, “nosotros”). El Portal se distribuye como un software y ha sido diseñado para incrementar el conocimiento de los profesionales de la salud en la Enfermedad de Gaucher.",
                    "Los términos “Usted”, “Usuario”, “su” y “sus”, se refieren en propiedad al profesional de la salud registrado que acceda al Portal y/o utilice sus servicios, con quien se entiende celebrado el acuerdo contenido en este documento, en adelante referido como los Términos de Uso.",
                    "Al acceder al Portal y/o usarlo, Usted se obliga y acepta sin ningún tipo de reserva estos Términos de Uso, constituyendo así, en un acuerdo legal y vinculante desde la fecha en la que accede y/o utiliza el Portal por primera vez. Los Términos de Uso describen sus derechos y obligaciones en relación con los servicios de información que se ofrecen a través del Portal (en adelante los “Servicios”). Si Usted no está de acuerdo con los Términos de Uso en todo o en parte, Usted no está autorizado para acceder al Portal y deberá abstenerse de utilizar los Servicios ofrecidos a través de este.",
                    "Takeda puede modificar estos Términos de Uso en cualquier momento, según su criterio, actualizando esta publicación. No obstante, Usted será informado de dichos cambios mediante un aviso en el Portal web. Le pediremos que acepte los cambios antes de acceder al Portal web. Si no los acepta o no puede cumplir los Términos de Uso actualizados, Usted debe dejar de usar el Portal web. Al utilizar o continuar utilizando el Portal web, Usted acepta someterse y cumplir con los Términos de Uso actualizados."
                ]
            },
            {
                "id": "t2",
                "title": "2. USO Y FINALIDAD DEL PORTAL",
                "txt": [
                    "En virtud de estos Términos de Uso, Takeda le concede autorización limitada, intransferible, no sublicenciable y no exclusiva a usar el Portal web para los fines aquí descritos. No obtendrá ninguna participación, propiedad ni ningún otro derecho sobre el Portal web, y no podrá desensamblar, descompilar, someter a técnicas de ingeniería inversa, crear trabajos derivados ni reproducir el Portal web por otros medios, de forma total ni parcial.",
                    "El Portal web se ofrece a través de una conexión segura a internet y ha sido desarrollado para incrementar el conocimiento de los profesionales de la salud en la Enfermedad de Gaucher. El contenido de este software, incluida toda la información, textos, gráficos, imágenes, enlaces, ilustraciones, diseños, iconos, fotografías, documentos, productos, programas informáticos, servicios y materiales escritos y de otro tipo (en conjunto, los “Materiales”), pueden contener información sobre cuestiones médicas y sanitarias cuya finalidad se limita a incrementar el conocimiento de los profesionales de la salud en la Enfermedad de Gaucher.",
                    "La información resultante ofrecida por el Portal web no tiene la intención de sustituir el criterio profesional del médico en la determinación del tratamiento que considere conveniente con base en su criterio profesional y experiencia."
                    ]
            },
            {
                "id": "t3",
                "title": "3. CONTENIDO DEL PORTAL",
                "txt": [
                    "El contenido proporcionado en el Portal cumple una función informativa para los profesionales de la salud, relacionada con la Enfermedad de Gaucher, incluyendo entre otros temas, notas, artículos, publicaciones de interés, videos y todo tipo de información relacionada con Enfermedad de Gaucher y la Compañía.",
                    "Takeda hará todos los esfuerzos razonables para garantizar que la información sea precisa en el momento en que sea publicada en el Portal; sin embargo, la información contenida en el Portal se proporciona “tal cual”, por lo que, en ningún caso, la información contenida dentro del Portal deberá considerarse como exhaustiva, precisa, completa o que de cualquier otra forma pueda utilizarse como un sustituto de una consulta, visita, llamada o asesoramiento médico.",
                    "El Portal podrá tener distintas secciones o funcionalidades, algunas de las cuales podrán ser de acceso restringido o limitado, de acuerdo con los criterios que defina la Compañía.",
                    "Takeda no está obligado a permitir el acceso a todo el contenido y funcionalidades del Portal, así como tampoco garantiza o se hace responsable por el acceso permanente al contenido y funcionalidades del Portal. Asimismo, Takeda podrá modificar, adaptar o cambiar la información contenida en el Portal, con el fin de mejorar o ajustar el servicio, o inhabilitar temporal o definitivamente el Portal, en cualquier momento, sin que sea necesario, en ninguna circunstancia, un previo aviso a los Usuarios.",
                    "Recuerde que el acceso y uso de este Portal y el contenido aquí incluido se realiza bajo su propio riesgo. Usted es responsable de a) verificar la exactitud, integridad o fiabilidad del contenido disponible a través del Portal y b) tomar todas las medidas necesarias para garantizar que el contenido que obtenga de este Portal esté libre de virus."
                    ]
            },
            {
                "id": "t4",
                "title": "4. AUTORIZACIONES QUE USTED OTORGA",
                "txt": [
                    "Si el Portal le permite que Usted, en su condición de Usuario, ingrese información y contenido que podría ser visible en el Portal, y al cual podría tener acceso Takeda y/u otros Usuarios, Usted manifiesta que se hace plenamente responsable de toda la información y del contenido insertado o publicado por Usted en o a través del Portal. En particular, Usted declara y garantiza a Takeda que posee todos los derechos, incluyendo los derechos de propiedad intelectual sobre toda la información y contenido, y/o está autorizado para insertar o publicar dicha información y contenido en el Portal. Takeda en ningún caso será responsable por la información o contenido que Usted u otro Usuario del Portal inserte o publique en el Portal.",
                    "Asimismo, al continuar el uso del Portal, Usted reconoce que cuando publica, inserta o suministra información o contenido en el Portal, Usted otorga a Takeda una licencia de uso no exclusiva, sub licenciable, transferible, a nivel mundial, por el término máximo de protección en cada país del mundo, para realizar la reproducción, comunicación pública, puesta a disposición, edición, modificación, traducción, almacenamiento digital, almacenamiento en forma electrónica e inclusión en bases de datos.",
                    "Por otra parte, Usted reconoce y acepta que Takeda podrá utilizar herramientas informáticas de recolección automática de datos, incluyendo cookies, para almacenar la información que se derive de su uso del Portal, con el fin de mejorar el servicio prestado a través de este y permitirle habilitar validaciones para acceder a las distintas funcionalidades del Portal."
                    ]
            },
            {
                "id": "t5",
                "title": "5. CONFIDENCIALIDAD Y SEGURIDAD",
                "txt": [
                    "Takeda ha adoptado las medidas que considera apropiadas para mantener la seguridad y la confidencialidad de la información facilitada a través del Portal web. No obstante, internet no es un medio totalmente seguro y no está bajo el control de Takeda. Usted utiliza internet por su propia cuenta y riesgo, de acuerdo con las leyes y normas vigentes"
                    ]
            },
            {
                "id": "t6",
                "title": "6. USO DE LOS MATERIALES",
                "txt": [
                    "A menos que se indique lo contrario, todos los Materiales son marcas comerciales o registradas sujetas a derechos de copia u otros derechos de propiedad intelectual propiedad de, controlados por o bajo licencia de Takeda o de terceros (en adelante, los “Proveedores Externos”).",
                    "Excepto como se establece en el presente documento, y en la medida en que lo permita la ley, se prohíbe la copia, reproducción, distribución, republicación, descarga, presentacion, publicación o transmisión de los Materiales en cualquier forma y por cualquier medio, incluido, sin limitación, los medios electrónicos, fotocopia, grabación u otros, sin la autorización previa por escrito de Takeda. Puede imprimir y descargar copias de estos Materiales desde el Portal web únicamente para su referencia personal y siempre que Usted conserve todos los avisos de derechos de autor y los demás avisos de propiedad correspondientes.",
                    "No debe usar ninguna de las ilustraciones, fotografías, secuencias de audio o video ni gráficos por separado del texto al que acompañan. Tampoco puede, sin la autorización previa por escrito de Takeda, utilizar cualquiera de los Materiales contenidos en este Portal web mediante réplicas, capturas de pantalla, marcos u otros métodos similares en cualquier otro servidor.",
                    "Se prohíbe toda utilización de los Materiales del Portal web en otras páginas web o en entornos de redes informativas con cualquier fin. Cualquier uso no autorizado de los Materiales contenidos en este Portal web puede infringir las leyes de copyright, marcas comerciales u otras, incluidas, sin limitación, las leyes de privacidad y publicidad, lo que puede dar lugar a importantes sanciones civiles y penales.",
                    "No le transferimos la propiedad sobre el Portal web. Usted es propietario o responsable del soporte o el dispositivo electrónico donde se visualizan o almacenan los Materiales, pero nosotros (o nuestros Proveedores externos) conservamos la plena propiedad sobre los Materiales y todos los derechos intelectuales y de propiedad industrial correspondientes. Usted es también totalmente responsable de la compatibilidad del Portal web, la capacidad para funcionar y la interfaz con su soporte o dispositivo electrónico. Takeda se reserva todos los derechos que no se otorguen expresamente."
                    ]
            },
            {
                "id": "t7",
                "title": "7. EXENCIÓN DE GARANTÍA; DISPONIBILIDAD DEL SERVICIO",
                "txt": [
                    "Takeda ofrece el portal web “tal cual” y rechaza expresamente todas las promesas, representaciones y garantías, tanto expresas como implícitas, de todo tipo, incluidas, sin limitación, las garantías de comerciabilidad o adecuación para un fin determinado, las garantías de no infracción, así como cualquier otra garantía derivada de la ejecución o utilización del portal web. Además, Takeda no ofrece garantías ni realiza representación alguna sobre el uso o los resultados del uso del portal web en términos de su corrección, precisión y/o fiabilidad. Takeda no garantiza que el uso o servicio prestado a través del portal esté libre de defectos ni que el servicio funcionará sin interrupciones.",
                    "En ningún caso, Takeda, sus filiales y subsidiarias, y sus respectivos funcionarios, directores, empleados, agentes, representantes, proveedores de información y licenciantes y sus respectivos cesionarios, serán responsables por cualquier acto directo, indirecto, incidental, consecuente, ejemplar, daños especiales, punitivos o de otro tipo, incluso si se informa de la posibilidad de dichos daños.",
                    "Aunque Takeda no garantiza la disponibilidad ininterrumpida del Portal web, Takeda realizará los esfuerzos razonables para asegurarse de que el Portal web funcione correctamente y de forma puntual. Takeda actualiza el Portal web con frecuencia y puede estar no disponible temporalmente para mantenimiento programado o no programado de Takeda o proveedores externos, o por otros motivos fuera del control razonable de Takeda."
                    ]
            },
            {
                "id": "t8",
                "title": "8. ADMINISTRACIÓN Y CANCELACIÓN DE LA CUENTA",
                "txt": [
                    "Takeda puede cancelar su acceso total o parcial al Portal web y cualquier servicio relacionado con este en cualquier momento si incumple estos Términos de Uso. Takeda se reserva el derecho, sin explicación, a negar el acceso a cualquier Usuario sospechoso de actividades ilegales, incluidas el uso ilegal o no autorizado de la contraseña del Usuario, y a denunciar dicha actividad ilegal o no autorizada a las autoridades respectivas.", 
                    "Takeda no tendrá ninguna obligación de informar a terceros ni será responsable de los posibles daños que puedan derivarse de la cancelación de la cuenta de un Usuario, incluido, pero no limitado al pago de compensaciones, pérdidas, reintegros o daños a cuenta de la pérdida de beneficios futuros, ni por otras razones cualesquiera derivadas de dicha cancelación.",
                    "La limitación de responsabilidad antes establecida se aplicará a cualquier reclamación, acción o procedimiento iniciado por el Usuario como resultado de la cancelación de la cuenta del Usuario por parte de Takeda."
                    ]
            },
            {
                "id": "t9",
                "title": "9. CONFORMIDAD LEGAL",
                "txt": [
                    "Takeda declara que el Portal web cumple con las leyes y normas que le resulten aplicables en la República de Colombia.", 
                    "Su utilización del Portal web implica que acepta no realizar ninguna acción que esté prohibida por las leyes anticorrupción locales u otras (en conjunto, las «Leyes anticorrupción») que puedan estar vigentes.",
                    "Takeda no ofrece garantía alguna en relación con el Portal web excepto (i) las garantías de conformidad legal establecidas en estos Términos de Uso; y (ii) aquellas garantías que Takeda no pueda refutar por disposición legal."
                    ]
            },
            {
                "id": "t10",
                "title": "10. DEFENSA ANTE RECLAMACIONES DE TERCEROS",
                "txt": [
                    "Su utilización del Portal web implica que entiende y acepta que el Usuario mantendrá a Takeda, sus filiales, representantes y empleados indemnes contra reclamaciones de terceros relacionadas con la utilización del Portal web por los Usuarios, a menos que dicha reclamación sea causada directamente por Takeda.",
                    "Takeda no será responsable en ningún caso de daños especiales, incidentales, indirectos, consecuentes o punitivos de cualquier tipo relacionados con el Portal web, incluidos sin limitación, la pérdida de beneficios o de datos, incluso si hubiera sido informada de antemano de la posibilidad de tales daños."
                    ]
            },
            {
                "id": "t11",
                "title": "11. PUBLICIDAD",
                "txt": [
                    "Usted acepta no utilizar el nombre, la marca ni los logotipos de Takeda para publicidad ni cualquier otro fin similar incluido, sin limitación, folletos, anuncios, notas de prensa, testimonios, páginas web, listas de referencia de clientes u otras representaciones implícitas o expresas sin la autorización previa por escrito de Takeda, que podrá ser rechazada bajo su exclusivo criterio. Usted acepta que esta cláusula es importante para Takeda y que las compensaciones legales podrían no ser adecuadas para proteger a Takeda contra el incumplimiento de esta cláusula. Usted acepta de antemano que Takeda tendrá derecho a obtener el amparo cautelar a su favor sin necesidad de demostrar los daños reales en caso de que el Usuario incumpla esta cláusula. Dicha indemnización no se considerará la única indemnización por cualquier incumplimiento de este Acuerdo, sino que se añadirá a cualquier otra indemnización disponible para Takeda por ley o equidad."
                    ]
            },
            {
                "id": "t12",
                "title": "12. LEGISLACIÓN APLICABLE",
                "txt": [
                    "Estos Términos de Uso se rigen por la ley vigente en la República de Colombia, sin referencia a las normas sobre conflictos de leyes. Si se considera que alguna de las cláusulas de estos Términos de Uso es ilegal, nula o inaplicable por cualquier motivo, dicha cláusula se considerara separable de estos Términos de Uso y no afectara la validez de las restantes cláusulas.",
                    "Estos Términos de Uso (y los documentos mencionados en ellos) constituyen la totalidad del acuerdo entre Usted y Takeda."
                    ]
            },
            {
                "id": "t13",
                "title": "13. RETRIBUCIÓN",
                "txt": [
                    "La provisión del acceso al Portal Web según estos de Uso no genera ningún derecho para Takeda  de obtener ninguna retribución, si bien las Partes pueden acordarlo de otro modo en un acuerdo separado. Este Acuerdo no se suscribe a cambio de ningún acuerdo implícito ni explícito para comprar, recomendar, utilizar ni recetar los productos de Takeda ni en razón del valor o volumen de cualquier negocio generado entre las partes. La provisión del acceso al Portal Web según estos Términos de Uso no implica la promoción de los productos u otras actividades de Takeda que incumplan las leyes, reglas o códigos de conducta relevantes."
                    ]
            },
            {
                "id": "t14",
                "title": "14. CONTACTO",
                "txt": [
                    "Para cualquier pregunta, comentario, inquietud o reclamación sobre estos Términos de Uso, consulte la información de contacto en el Portal web."
                    ]
            },
            {
                "id": "t15",
                "title": "15. ACEPTACIÓN Y AUTORIZACIÓN",
                "txt": [
                    "Al acceder y utilizar el Portal web, confirma que ha leído, entendido y acepta quedar legalmente vinculado a estos Términos de Uso. Si no acepta o no puede cumplir los Términos de Uso en su totalidad, no tiene autorización para utilizar el Portal web.",
                    "El Portal web se pone a disposición de los usuarios sobre la base de estos Términos de Uso.  El uso de este Portal web, que depende de que haga clic en «acepto», implica su entendimiento y aceptación a estos términos.",
                    "Su aceptación de estos Términos de Uso sustituirá cualquier aceptación anterior de Términos de Uso relacionados con su uso del Portal web."
                    ]
            }
        ]
    },
    "privacy_policy": {
        "breadcrumb": [
            {
                "link": "/",
                "name": "Inicio"
            }
        ],
        "current_breadcrumb": "Aviso de privacidad",
        "title": "AVISO DE PRIVACIDAD",
        "date": "Fecha: Junio de 2021",
        "content": [
            {   
                "id": "t1",
                "txt": [
                    "Su privacidad y la protección de sus datos personales es importante para nosotros. Este Aviso de Privacidad explica el tipo de datos personales que Takeda puede recopilar acerca de usted y cómo los usamos.",
                    "Takeda es un grupo de empresas que opera a nivel mundial. La empresa Takeda incluye la sociedad matriz Takeda Pharmaceutical Company Limited y sus entidades afiliadas (en conjunto, \"Takeda\" o \"Nosotros\"). En caso de que nos contacte para reportar un problema, presentar una queja o comunicarse con nosotros por otro motivo, recopilamos información para resolver su consulta. Para obtener información de contacto relacionada con su filial local, consulte el sitio web de Takeda en <a href='https://www.takeda.com/who-we-are/company-information/worldwide-offices/' target='_blank'>https://www.takeda.com/who-we-are/company-information/worldwide-offices/</a>.",
                    "Este sitio web es provisto para proporcionar información general sobre temas de salud relacionados con la enfermedad de Gaucher. Para este propósito, podemos recopilar, usar, almacenar y divulgar información sobre usted, incluido su nombre, apellido, tipo de documento, número de documento, registro médico, teléfono, país, especialidad.",
                    "Cuando visita el sitio web, también podemos recopilar automáticamente información técnica como: Dirección IP (Protocolo de Internet, por sus siglas en inglés), su información de acceso (login), tipo y versión del navegador, sus datos de uso, etc.",
                    "<strong>Este sitio web es administrado por Baxalta Colombia S.A.S. en nombre de Takeda.</strong>",
                    "Los datos personales recopilados sobre usted se compartirán con proveedores de alojamiento (hosting), profesionales de la salud, proveedores de servicios, socios comerciales.",
                    "El uso que damos a sus datos personales como se describió anteriormente está basado en servir a nuestros intereses legítimos para promover nuestros productos y la conciencia de la enfermedad; cumplir con nuestras obligaciones contractuales hacia usted; cumplir con nuestras obligaciones legales; su consentimiento.",
                    "Podemos compartir su información con proveedores de servicios y socios comerciales de todo el mundo con los que colaboramos para cumplir con los fines anteriores (por ejemplo, servicios para dar cumplimiento, apoyo técnico, consultores y asesores externos). También podemos compartir su información con otras filiales y subsidiarias de Takeda. El intercambio de sus datos personales puede implicar transferencias a otros países. Es posible que las leyes de protección de datos y privacidad de estos países no proporcionen el mismo nivel de protección que provee su país de origen. Takeda pondrá en marcha medidas de seguridad para proteger adecuadamente sus datos personales en relación con dichas transferencias.",
                    "Conservamos sus datos personales durante el tiempo que utilice este sitio web/aplicación. También podemos conservar sus datos durante un período razonable tras el cumplimiento de nuestros servicios o la terminación de su relación con nosotros o la interrupción del uso de nuestra aplicación por parte suya, a menos que la ley requiera o permita un período de retención más largo.",
                    "Tiene derecho a solicitar el acceso, rectificación y eliminación de sus datos personales o que se restrinja su uso. Si desea ejercer sus derechos individuales, póngase en contacto con nosotros accediendo a nuestro formulario web <a href='https://privacyportal.onetrust.com/webform/ccbe7f52-9dc0-4f13-9038-2289ae6e6a3d/f4f875cc-f477-43fc-bc1c-ba2f115e339e' target='_blank'>Derechos Individuales</a>. Si tiene una queja sobre cómo se procesan sus datos personales, también tiene derecho a ponerse en contacto con la Autoridad de Protección de Datos de su país.",
                    "<h4>Contáctenos:</h4>",
                    "Si tiene preguntas sobre este Aviso de Privacidad, comuníquese con nosotros por correo electrónico a la dirección que aparece a continuación.",
                    "Dirección de correo electrónico: <a href='mailto:privacyoffice@takeda.com' target='_blank'>privacyoffice@takeda.com</a> y/o <a href='mailto:privacy.andean@takeda.com' target='_blank'>privacy.andean@takeda.com</a>",
                    "Para obtener información más detallada sobre cómo Takeda procesa sus datos personales y sus derechos relacionados con este procesamiento, consulte el Aviso de Privacidad de Takeda en <a href='https://www.takeda.com/es-co/' target='_blank'>www.takeda.com/es-co/</a>."
                ]
            }
        ]
    }
}



export default data;