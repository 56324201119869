import React, { Component } from "react";
import data from "../shared/data";
import { Link } from "react-router-dom";
import cookiePolicy from "../static/cookies-policy.pdf";

/*Footer component*/
class Footer extends Component {
  render() {
    // Var for when anyone decides to include the newsletter
    var gaucherNewsLetter = <div />;
    // Footer description
    const description = data.footer.description.map((item, index) => (
      <p key={index}>{item}</p>
    ));
    return (
      <div>
        {gaucherNewsLetter}
        {/* footer data*/}
        <footer>
          <div className="container text-justify pt-5">
            <div className="row">
              <div className="col-12 col-md-6">
                {/*<p>
                  <strong>{data.footer.title}</strong>
                </p>*/}
                {description}
              </div>
              <div className="col-12 col-md-4">
                <p>{data.footer.disclaimer}</p>
                <p>{data.footer.disclaimer_2}</p>
                <p>
                  <strong> © Takeda </strong>
                  <Link to="/terminos"> {data.footer.legal_statement}</Link><span> | </span>
                  <Link to="/aviso-de-privacidad"> {data.footer.privacy_policy}</Link><span> | </span>
                  <a href={cookiePolicy} target="_blank">
                    {" "}
                    {data.footer.cookies_information}
                  </a>
                </p>
                <i>{data.anprom.anprom}</i>
              </div>
              <div className="col-12 col-md-2 pt-5">
                <a href="https://www.takeda.com/es-co">
                  <img src={data.footer.logo} alt="" className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
