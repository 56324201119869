import React, { Component } from "react";
import Cols from "./misc/ColsComponent";
import data from "../shared/data";
import ImageComb1 from "./misc/ImageComb1Component";
import Banner from "./BannerComponent";
import ReactGA from "react-ga";
import { Helmet} from 'react-helmet';

/* Diagnosis component corresponding to route /diagnosis/*/
class Diagnosis extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname,
        title: 'Diagnóstico'
      }
    });
  }

  render() {
    var comp = this;
    //Refs for custom component
    const customReferences = data.diagnosis.custom_comb.refs.map(
      (ref, index) => <li className="references white" key={index}>{ref}</li>
    );
    return (
      <div>
        {/* META TAGS */}
        <Helmet>
          <title>Diagnóstico - Enfermedad de Gaucher | Enfermedades Genéticas</title>
          <meta name="description" content="La enfermedad de Gaucher puede ser difícil de diagnosticar debido a varias razones, entre ellas la variabilidad en las características, tipo clínico y gravedad de la enfermedad …" />
          <meta name="keywords" content="Diagnóstico enfermedad de Gaucher, Pruebas de función hepática, Medición de actividad de la glucocerebrosidasa" />
        </Helmet>
        <Banner
          banner={data.diagnosis.banner_img}
          parser={this.props.parser}
          msg={data.diagnosis.banner_msg}
          desc={data.diagnosis.banner_desc}
          title={data.effectbar.call_to_action.title}
          content={data.effectbar.call_to_action.content}
          btn_text={data.effectbar.call_to_action.btn_text}
        />
        {/* Call to action organized in columns*/}
        <a className="anchor" id="interest_areas" />
        <Cols
          color={data.diagnosis.cols.color}
          parser={this.props.parser}
          content={data.diagnosis.cols.content}
        />

        {/* Diagnosis information*/}
        <a className="anchor" id="diagnosis" />
        <ImageComb1
          title={data.diagnosis.comb1_1.title}
          parser={this.props.parser}
          sub1={data.diagnosis.comb1_1.sub1}
          txt1={data.diagnosis.comb1_1.txt1}
          img={data.diagnosis.comb1_1.img}
          imgTitle={data.diagnosis.comb1_1.imgtitle}
          imgdesc={data.diagnosis.comb1_1.imgdesc}
          references={data.diagnosis.comb1_1.refs}
          color={data.diagnosis.comb1_1.color}
        />

        <a className="anchor" id="diagnosis_stablishment" />
        <ImageComb1
          title={data.diagnosis.comb1_2.title}
          parser={this.props.parser}
          txt1={data.diagnosis.comb1_2.txt1}
          img={data.diagnosis.comb1_2.img}
          imgdesc={data.diagnosis.comb1_2.imgdesc}
          references={data.diagnosis.comb1_2.refs}
          color={data.diagnosis.comb1_2.color}
        />
        {/* Black component*/}
        <a className="anchor" id="confirming_diagnosis" />

        <div className="custom-banner text-justify">
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-12">
                <h4>
                  {comp.props.parser.parse(data.diagnosis.custom_comb.title)}
                </h4>
              </div>
              <div className="col-12 p-3" />
              <div className="col-12 col-md-4">
                <p>
                  {comp.props.parser.parse(data.diagnosis.custom_comb.txt1[0])}
                </p>
                <p>
                  {comp.props.parser.parse(data.diagnosis.custom_comb.txt1[1])}
                </p>
              </div>
              <div className="col-12 col-md-4">
                <p>
                  {comp.props.parser.parse(data.diagnosis.custom_comb.txt1[2])}
                </p>
              </div>
              <div className="col-12 col-md-4">
                <ol>{customReferences}</ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Diagnosis;
